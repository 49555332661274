import { HttpClient } from '@angular/common/http';
import { ProformaService } from './../../../../services/proforma.service';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControl, Validators, } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Tokens } from 'src/app/interfaces/tokens.interface';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { requestCambiarContra, requestExisteUsuario } from 'src/app/proforma/domain/request/proforma_request';
import { GenerarcotComponent } from 'src/app/proforma/presentacion/components/generarcot/generarcot.component';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { LoginUserCU } from 'src/app/usuario/application/user-logincu';
import { ResponseLogin, UserEntity } from 'src/app/usuario/domain/user-entity';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cambiaContrasena',
  templateUrl: './cambiaContrasena.component.html',
  styleUrls: ['./cambiaContrasena.component.css']
})
export class CambiaContrasenaComponent implements OnInit {
  cambiarContrasenaForm: FormGroup;
  hide = true;
  hide1 = true;
  hide2 = true;
  valido: boolean = false;
  muestra: boolean = false;
  borderColorR: object = { 'border': '1px solid red' };
  borderColorV: object = { 'border': '1px solid green' };
  color: { [key: string]: string };
  @Output() outputLogin: EventEmitter<UserEntity> = new EventEmitter<UserEntity>();


  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: any, private util: UtilService, private readonly reference: MatDialogRef<GenerarcotComponent>, private readonly proformaService: ProformaRepository, private userlogincu: LoginUserCU, private http: HttpClient,
    private readonly storage: StorageService,) { }

  group: FormGroup
  inicializaFormulario() {
    this.cambiarContrasenaForm = new FormGroup({
      usuario: new FormControl(this.data.nombreUsuario, Validators.required),
      nuevaContrasena: new FormControl(null, Validators.required),
      confirmarNuevaContrasena: new FormControl("", Validators.required),
      antiguaContrasena: new FormControl("", Validators.required),
    });
    this.group = new FormGroup({
      user: new FormControl(this.data.nombreUsuario, Validators.required),
      old: new FormControl("", Validators.required),
      new: new FormControl("", Validators.required),
      confirm: new FormControl("", [Validators.required],)
    })
    this.group.get('confirm')?.disable();
  }

  validateButton: boolean = false
  ngOnInit(): void {
    this.inicializaFormulario();
    this.validateButton = false
  }

  cambiarContrasena() {
    const values = this.cambiarContrasenaForm.value;
    const user: UserEntity = <UserEntity>{};
    user.password = values.antiguaContrasena;
    user.usuario = values.usuario;

    const listausuario: ResponseLogin = <ResponseLogin>{}
    const requestCambiaContra: requestCambiarContra = <requestCambiarContra>{};
    this.http
      .post(`${environment.PATH_API}/autenticacion/login`, user)
      .subscribe((data: Tokens) => {
        this.storage.save('accessToken', data.datos.result.token);
        this.storage.save('usuario', data.datos.result.nombreUsuario);
        this.storage.save('codusuario', user.usuario);
        this.storage.save('codcompania', data.datos.result.codigoempresa);
        this.storage.save('compania', data.datos.result.desEmpresa);
        this.storage.save('userLogged', "true");
        this.storage.save('rol', data.datos.result.menu.codigoRol.toString())
        this.storage.save('correo', data.datos.result.correo);


        listausuario.datos = data.datos

        if (values.nuevaContrasena != values.confirmarNuevaContrasena) {
          this.util.showMessage("Las contraseñas no coinciden");
          return;
        } else {
          requestCambiaContra.contrasenaNueva = values.nuevaContrasena;
          requestCambiaContra.nombreUsuario = values.usuario;
        }

        this.proformaService.cambiaContrasena(requestCambiaContra).subscribe(resp => {
          this.util.showMessage(resp.datos.result[0].mensaje);
          this.cerrarVentana();
        })
      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);
      })
  }

  cerrarVentana() {
    this.reference.close();
  }


  ingresaTexto() {
    const values = this.cambiarContrasenaForm.value;
    if (values.nuevaContrasena != "" && values.confirmarNuevaContrasena != "") {
      this.muestra = true;
      if (values.nuevaContrasena == values.confirmarNuevaContrasena) {
        this.valido = true;
        this.color = { 'color': 'green' };
      } else {
        this.valido = false;
        this.color = { 'color': 'red' };
      }
    } else {
      this.muestra = false;
    }
  }

  mensaje: string = ""
  validate(type: string): void {
    this.validateButton = false

    const values = this.group.value
    if (type === "new") {
      this.group.get('confirm')?.disable();
      const password = values.new;

      // Validación: mínimo 8 caracteres
      const minLengthValid = password.length >= 8;
      // Validación: contiene al menos una letra mayúscula
      const hasUppercase = /[A-Z]/.test(password);
      // Validación: contiene al menos una letra minúscula
      const hasLowercase = /[a-z]/.test(password);
      // Validación: contiene al menos un número
      const hasNumber = /\d/.test(password);
      // Validación: contiene al menos un carácter especial
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      // Construir el mensaje basado en lo que falta
      if (!minLengthValid) {
        this.mensaje = 'La contraseña debe tener al menos 8 caracteres.\n';
        return
      }
      if (!hasUppercase) {
        this.mensaje = 'La contraseña debe contener al menos una letra mayúscula.\n';
        return
      }
      if (!hasLowercase) {
        this.mensaje = 'La contraseña debe contener al menos una letra minúscula.\n';
        return
      }
      if (!hasNumber) {
        this.mensaje = 'La contraseña debe contener al menos un número.\n';
        return
      }
      if (!hasSpecialChar) {
        this.mensaje = 'La contraseña debe contener al menos un carácter especial.\n';
        return
      }
      this.mensaje = ''
      this.group.get('confirm')?.enable();

    }
    if (type === "confirm") {
      const password = values.new;
      const confirm = values.confirm;

      if (password === confirm) {
        this.mensaje = ''
        this.validateButton = true
      } else {
        this.mensaje = "Las contraseñas no coinciden."
      }
    }
  }

  updatePassword():void{
    const values = this.group.value;
    const user: UserEntity = <UserEntity>{};
    user.password = values.old;
    user.usuario = values.user;

    const listausuario: ResponseLogin = <ResponseLogin>{}
    const requestCambiaContra: requestCambiarContra = <requestCambiarContra>{};
    this.http
      .post(`${environment.PATH_API}/autenticacion/login`, user)
      .subscribe((data: Tokens) => {
        this.storage.save('accessToken', data.datos.result.token);
        this.storage.save('usuario', data.datos.result.nombreUsuario);
        this.storage.save('codusuario', user.usuario);
        this.storage.save('codcompania', data.datos.result.codigoempresa);
        this.storage.save('compania', data.datos.result.desEmpresa);
        this.storage.save('userLogged', "true");
        this.storage.save('rol', data.datos.result.menu.codigoRol.toString())
        this.storage.save('correo', data.datos.result.correo);


        listausuario.datos = data.datos

        requestCambiaContra.contrasenaNueva = values.new;
        requestCambiaContra.nombreUsuario = values.user;

        this.proformaService.cambiaContrasena(requestCambiaContra).subscribe(resp => {
          this.util.showMessage(resp.datos.result[0].mensaje);
          this.cerrarVentana();
        })
      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);
      })
  }
}

