import { Observable } from "rxjs";
import { ReporteContratosRequest, ReporteKunaqPorImportadorRequest, ReporteQunacRequest, ReporteTeusFechaEtaRequest, ReporteVentasIRequest } from "./request/reporte-ventas-request";
import { IndicadorEmbarqueFechaLlegadaResponse, IndicadorTeusFechaLlegadaResponse, RankingTeusEntidadesResponse, ReporteContratosResponse, ReporteFletePorLineaResponse, ReporteKunaqClientesDelfinResponse, ReporteKunaqClientesNoDelfinResponse, ReporteKunaqPorImportadorResponse, ReporteKunaqPorImportadorTipoResponse, ReporteQunacResponse, ReporteTeusFechaEtaResponse, ReporteVentasGraficosResponse, ReporteVentasIIIResponse, ReporteVentasIIResponse, ReporteVentasIResponse } from "./response/reporte-ventas-response";
import { ListaMenuReportesRequest, operacionesGastoEmpresaRequest, ReporteCicloEmbarqueRequest, ReporteClienteAnio03Request, ReporteClientesAnioEtaDetalleRequest, ReporteServicioContenedorRequest, TransaccionEjecutivoCostoRequest } from "src/app/reporte-ciclo-embarque/domain/reporte-ciclo-embarque-request";
import { ListaMenuReportesResponse, ReporteCicloEmbarqueDetalleResponse, ReporteCicloEmbarqueResponse, ReporteClienteAnio03Response, ReporteClienteAnioTipo03Response, ReporteClienteAnioTipo04Response, ReporteClientesAnioEtaDetalleResponse, ReporteClientesAnioEtaResumenResponse, ReporteFlujoProyectado07Response, ReporteFrecuenciaClienteDetalleResponse, ReporteFrecuenciaClienteResumen02Response, ReporteFrecuenciaClienteResumenResponse, ReporteParetodeCarteraDetalleResponse, ReporteParetodeCarteraResumenResponse, ReporteServicioContenedorResponse } from "src/app/reporte-ciclo-embarque/domain/reporte-ciclo-embarque-response";
import { GeneralResponse } from "src/app/comercial/reffer/domain/reffer-response";
import { AvanceVentasRequest } from "src/app/proyeccion-cuotas/domain/request/reporte-request";
import { ListaAvanceVentasResumenResponse } from "src/app/efectividad-ventas/domain/response/efectividad-ventas-response";
import { ReporteCarteraEjecutivoRequest } from "src/app/comercial/reportes/componentes/reporte-cartera-ejecutivo/domain/reporte-cartera-ejecutivo.request";
import { ReporteCarteraEjecutivoDetalleResponse, ReporteCarteraEjecutivoExportarResponse, ReporteCarteraEjecutivoPartidasResponse, ReporteCarteraEjecutivoResumenResponse } from "src/app/comercial/reportes/componentes/reporte-cartera-ejecutivo/domain/reporte-cartera-ejecutivo.response";

export abstract class ReportesRepository {


    abstract ListaReporteVentasI(fd: ReporteVentasIRequest): Observable<ReporteVentasIResponse>;
    abstract ListaReporteVentasIII(fd: ReporteVentasIRequest): Observable<ReporteVentasIIIResponse>;
    abstract ListaReporteVentasII(fd: ReporteVentasIRequest): Observable<ReporteVentasIIResponse>;
    abstract ReporteVentasGraficos(fd: ReporteVentasIRequest): Observable<ReporteVentasGraficosResponse>;
    abstract ReporteTeusFechaEta(fd: ReporteTeusFechaEtaRequest): Observable<ReporteTeusFechaEtaResponse>;
    abstract ReporteCicloEmbarque(fd: ReporteCicloEmbarqueRequest): Observable<ReporteCicloEmbarqueResponse>;
    abstract ReporteCicloEmbarqueDetalle(fd: ReporteCicloEmbarqueRequest): Observable<ReporteCicloEmbarqueDetalleResponse>;
    abstract NuevoReporteSLI(fd: ReporteServicioContenedorRequest): Observable<ReporteServicioContenedorResponse>;
    abstract ListaMenuReportes(fd: ListaMenuReportesRequest): Observable<ListaMenuReportesResponse>;
    abstract ReporteClientesAnioEtaDetalle(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteClientesAnioEtaDetalleResponse>;
    abstract ReporteClientesAnioEtaResumen(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteClientesAnioEtaResumenResponse>;
    abstract ReporteParetodeCarteraResumen(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteParetodeCarteraResumenResponse>;
    abstract ReporteParetodeCarteraDetalle(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteParetodeCarteraDetalleResponse>;
    abstract TransaccionEjecutivoCosto(fd: TransaccionEjecutivoCostoRequest): Observable<GeneralResponse>;
    abstract ReporteClienteAnio03(fd: ReporteClienteAnio03Request): Observable<ReporteClienteAnio03Response>;
    abstract ReporteClienteAnioTipo03(fd: ReporteClienteAnio03Request): Observable<ReporteClienteAnioTipo03Response>;
    abstract ReporteClienteAnioTipo04(fd: ReporteClienteAnio03Request): Observable<ReporteClienteAnioTipo04Response>;
    abstract ReporteFlujoProyectado07(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFlujoProyectado07Response>;
    abstract ReporteFrecuenciaClienteResumen(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFrecuenciaClienteResumenResponse>;
    abstract ReporteFrecuenciaClienteDetalle(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFrecuenciaClienteDetalleResponse>;
    abstract ReporteFrecuenciaClienteResumen02(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFrecuenciaClienteResumen02Response>;
    abstract operacionesGastoEmpresa(fd: operacionesGastoEmpresaRequest): Observable<GeneralResponse>;
    abstract ListarGastoEmpresa(fd: ReporteClientesAnioEtaDetalleRequest): Observable<ReporteFlujoProyectado07Response>;
    abstract ResumenAvanceComercial(fd: AvanceVentasRequest): Observable<ListaAvanceVentasResumenResponse>;
    abstract ReporteContratos(fd: ReporteContratosRequest): Observable<ReporteContratosResponse>;
    abstract ReporteQunac(fd: ReporteQunacRequest): Observable<ReporteQunacResponse>;
    abstract ReporteKunaqClientesDelfin(fd: ReporteQunacRequest): Observable<ReporteKunaqClientesDelfinResponse>;
    abstract ReporteKunaqClientesNoDelfin(fd: ReporteQunacRequest): Observable<ReporteKunaqClientesNoDelfinResponse>;
    abstract IndicadorEmbarqueFechaLlegada(fd: ReporteQunacRequest): Observable<IndicadorEmbarqueFechaLlegadaResponse>;
    abstract IndicadorTeusFechaLlegada(fd: ReporteQunacRequest): Observable<IndicadorTeusFechaLlegadaResponse>;
    abstract RankingTeusEntidades(fd: ReporteQunacRequest): Observable<RankingTeusEntidadesResponse>;
    abstract ReporteKunaqPorImportador(fd: ReporteKunaqPorImportadorRequest): Observable<ReporteKunaqPorImportadorResponse>;
    abstract ReporteKunaqPorImportadorTipo(fd: ReporteKunaqPorImportadorRequest): Observable<ReporteKunaqPorImportadorTipoResponse>;
    abstract ReporteFletePorLinea(request:ReporteKunaqPorImportadorRequest): Observable<ReporteFletePorLineaResponse>
    
    abstract ReporteCarteraEjecutivoResumen(request:ReporteCarteraEjecutivoRequest): Observable<ReporteCarteraEjecutivoResumenResponse>
    abstract ReporteCarteraEjecutivoDetalle(request:ReporteCarteraEjecutivoRequest): Observable<ReporteCarteraEjecutivoDetalleResponse>
    abstract ReporteCarteraEjecutivoExportar(request:ReporteCarteraEjecutivoRequest): Observable<ReporteCarteraEjecutivoExportarResponse>
    abstract ReporteCarteraEjecutivoPartidas(request:ReporteCarteraEjecutivoRequest): Observable<ReporteCarteraEjecutivoPartidasResponse>


    
}
