import { DatosCompartidosService } from 'src/app/services/datos-compartidos.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { OperacionRepository } from 'src/app/repositorio/operacion.repository';
import { UtilService } from 'src/app/services/util.service';
import { FormControl, FormGroup } from '@angular/forms';
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { Subject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ListaDetalleProforma, ListaTarifa } from 'src/app/proforma/domain/response/proforma_response';
import { tarifaRequest } from 'src/app/proforma/domain/request/proforma_request';

@Component({
  selector: 'app-buscar-contrato',
  templateUrl: './buscar-contrato.component.html',
  styleUrls: ['./buscar-contrato.component.css']
})
export class BuscarContratoComponent {
  contrato: any;
  @Output() dataUpdated = new EventEmitter<any>();
  fila: any
  key: any
  row: any;

  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }

  constructor(public dialogRef: MatDialogRef<BuscarContratoComponent>,
    private readonly servOperaciones: OperacionRepository,
    private readonly proformaService: ProformaRepository,
    private util: UtilService,
    private datosCompartidosService: DatosCompartidosService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.contratos = this.datosCompartidosService.getlistaDatosContratos();
  }

  protected _onDestroy = new Subject<void>();
  group_contrato: FormGroup;
  codtransportista: number;
  dataTableContrato: any[] = [];
  dataEnviada: any[] = [];
  contratos: any;
  requestTarifa: tarifaRequest;
  cantidad: number;

  dataTable: any[] = []
  metadataTable: newmetadate[] = [
    { field: 'select', title: '', filterType: 'boolean', inputType: 'checkboxEditOpcionUnica' },
    { field: 'Item', title: 'ITEM', inputType: 'int', filterType: 'numeric' },
    { field: 'puertoorigen', title: 'ORIGEN', inputType: 'text', filterType: 'text' },
    { field: 'puertodestino', title: 'DESTINO', inputType: 'text', filterType: 'text' },
    { field: 'linea', title: 'TRANSPORTISTA', inputType: 'text', filterType: 'text' },
    { field: 'descripcion', title: 'CONTRATO', inputType: 'text', filterType: 'text' },
    { field: 'paquete', title: 'PAQUETE', inputType: 'text', filterType: 'text' },
    { field: 'dcot_cantidad', title: 'CANTIDAD', filterType: 'numeric', inputType: 'int' },
    { field: 'conT_FecIni', title: 'FECHA INICIO', filterType: 'text', inputType: 'date' },
    { field: 'conT_FecFin', title: 'FECHA FIN', filterType: 'text', inputType: 'date' },
    { field: 'soes_diassobrestadiacliente', title: 'SOBRESTADIA', inputType: 'int', filterType: 'numeric' },
    { field: 'dcot_preciouniventa', title: 'VALOR', inputType: 'text', filterType: 'numeric' },
    { field: 'dcot_totaluniventa', title: 'TOTAL', inputType: 'text', filterType: 'numeric' }
  ];

  ngOnInit(): void {
    const parsedData = JSON.parse(this.data);
    // console.log('Datos recibidos en el modal:', parsedData);
    if (parsedData != null) {
      this.requestTarifa = parsedData[0].requestTarifa;
      this.cantidad = parsedData[0].cantidad;
    }
    // console.log('Datos recibidos en el modal this.requestTarifa:', this.requestTarifa);
    // console.log('Datos recibidos en el modal this.cantidad:', this.cantidad);
    this.cargaTarifa()
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ListaContedorTarifaGeneral: ListaTarifa[] = []
  cargaTarifa() {
    this.proformaService.listarTarifa(this.requestTarifa).subscribe(response => {
      if (response.datos.status === 200) {
        // console.log(" response.datos.result de buscar-contrato:", response.datos.result)
        this.dataTable = response.datos.result.map((registro: any, index: number) => {
          const total = this.cantidad * registro.valor;
          return {
            ...registro,
            select: false,
            Item: index + 1,
            dcot_cantidad: this.cantidad,
            dcot_preciounicosto: registro.costo,
            dcot_preciouniventa: registro.valor,
            dcot_totalunicosto: registro.costo * this.cantidad,
            dcot_totaluniventa: registro.valor * this.cantidad
          };
        });

        // console.log("info del this.dataTable de contrato", this.dataTable)
      }
    })
  }

  aceptar() {
    const filasSeleccionadas = this.getSelectedRows()
    if (filasSeleccionadas.length <= 0) { this.util.showMessage("Seleccione al menos una fila"); return; }
    else {
      // filasSeleccionadas.forEach(row => {

      // });
      this.dialogRef.close(filasSeleccionadas);
      this.dataUpdated.emit(filasSeleccionadas);
      this.ngOnDestroy()
    }
  }

  getSelectedRows() {
    return this.dataTable.filter(row => row.select === true);
  }


  cancelar() {
    this.dialogRef.close(null)
    this.ngOnDestroy()
  }

}
