<div class="tituloPantalla">
  <mat-toolbar style="height: 33px;" fxLayout class="tituloPantalla">
    <h3 class="title">DATOS TRANSPORTISTAS</h3>
  </mat-toolbar>
</div>

<div class="form-container">
  <form [formGroup]="group">
    <div class="inputs">
      <!-- Transportista Adicional 1 -->
      <div class="example-full-width-alterno100"
        style="margin-top: 7%; display: flex; align-items: center; width: 100%;">
        <div style="display: flex; align-items: center; margin-right: 10px; width: 30px;">
          <p-radioButton formControlName="selectedTransportista" inputId="radioTransportista1" value="1">
          </p-radioButton>
        </div>
        <span class="p-float-label" style="flex: 1;">
          <input [ngClass]="{ readonly: readOnlyTransportista1txt }" pInputText
            formControlName="transportistaAdicional1" appendTo="body" placeholder="   " />
          <label>Transportista 1</label>
        </span>
        <span class="p-float-label" style="flex: 1; margin-left: 15px;">
          <input [ngClass]="{ readonly: readOnlyContTransportista1 }" pInputText type="text"
            formControlName="numeroContrato1" placeholder="   " class="p-inputtext-sm" style="width: 100%;">
          <label>Número Contrato</label>
        </span>
        <span class="p-float-label" style="flex: 1;">
          <p-dropdown [options]="listaPaquete" [ngClass]="{ readonly: readOnlyContenedor1 }" placeholder="‎ "
            optionLabel="nombre" formControlName="codContenedor1" optionValue="codigo" class="p-inputtext-sm"
            placeholder=" ">
          </p-dropdown>
          <label for="float-label">Contenedor</label>
        </span>
        <!-- <span class="p-float-label" style="flex: 1; margin-left: 15px;">
          <input [ngClass]="{ readonly: readOnlyContenedor1 }" pInputText type="text" formControlName="codContenedor1"
            placeholder=" " class="p-inputtext-sm" style="width: 100%;">
          <label>Contenedor</label>
        </span> -->
      </div>

      <!-- Transportista Adicional 2 -->
      <div class="example-full-width-alterno100"
        style="margin-top: 6%; display: flex; align-items: center; width: 100%;">
        <div style="display: flex; align-items: center; margin-right: 10px; width: 30px;">
          <p-radioButton formControlName="selectedTransportista" inputId="radioTransportista2" value="2">
          </p-radioButton>
        </div>
        <span class="p-float-label" style="flex: 1;">
          <input [ngClass]="{ readonly: readOnlyTransportista2txt }" pInputText placeholder=" "
            formControlName="transportistaAdicional2" appendTo="body" />
          <label>Transportista 2</label>
        </span>
        <span class="p-float-label" style="flex: 1; margin-left: 15px;">
          <input [ngClass]="{ readonly: readOnlyContTransportista2 }" pInputText type="text"
            formControlName="numeroContrato2" placeholder=" " class="p-inputtext-sm" style="width: 100%;">
          <label>Número Contrato</label>
        </span>
        <span class="p-float-label" style="flex: 1;">
          <p-dropdown [options]="listaPaquete" [ngClass]="{ readonly: readOnlyContenedor2 }" placeholder="‎ "
            optionLabel="nombre" formControlName="codContenedor2" optionValue="codigo" class="p-inputtext-sm"
            placeholder=" ">
          </p-dropdown>
          <label for="float-label">Contenedor</label>
        </span>
        <!-- <span class="p-float-label" style="flex: 1; margin-left: 15px;">
          <input [ngClass]="{ readonly: readOnlyContenedor2 }" pInputText type="text" formControlName="codContenedor2"
            placeholder=" " class="p-inputtext-sm" style="width: 100%;">
          <label>Contenedor</label>
        </span> -->
      </div>

      <!-- Transportista Adicional 3 -->
      <div class="example-full-width-alterno100"
        style="margin-top: 6%; display: flex; align-items: center; width: 100%;">
        <div style="display: flex; align-items: center; margin-right: 10px; width: 30px;">
          <p-radioButton formControlName="selectedTransportista" inputId="radioTransportista3" value="3">
          </p-radioButton>
        </div>
        <span class="p-float-label" style="flex: 1;">
          <input [ngClass]="{ readonly: readOnlyTransportista3txt }" pInputText placeholder=" "
            formControlName="transportistaAdicional3" appendTo="body" />
          <label>Transportista 3</label>
        </span>
        <span class="p-float-label" style="flex: 1; margin-left: 15px;">
          <input [ngClass]="{ readonly: readOnlyContTransportista3 }" pInputText type="text"
            formControlName="numeroContrato3" placeholder=" " class="p-inputtext-sm" style="width: 100%;">
          <label>Número Contrato</label>
        </span>
        <span class="p-float-label" style="flex: 1;">
          <p-dropdown [ngClass]="{ readonly: readOnlyContenedor3 }" [options]="listaPaquete" placeholder="‎ "
            optionValue="codigo" optionLabel="nombre" formControlName="codContenedor3" class="p-inputtext-sm"
            placeholder=" " [filter]="true">
          </p-dropdown>
          <label for="float-label">Contenedor</label>
        </span>
        <!-- <span class="p-float-label" style="flex: 1; margin-left: 15px;">
          <input [ngClass]="{ readonly: readOnlyContenedor3 }" pInputText type="text" formControlName="codContenedor3"
            placeholder=" " class="p-inputtext-sm" style="width: 100%;">
          <label>Contenedor</label>
        </span> -->
      </div>

      <!-- Botones -->
      <div class="a-example-full-width-alterno-content" style="margin-top: 3%; width: 100%;">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
          <p-button label="ACEPTAR" icon="pi pi-check" [disabled]="group.invalid" (onClick)="guardar()"
            class="guardar-button"></p-button>
          <p-button label="CANCELAR" icon="pi pi-times" class="p-button-secondary" (onClick)="closeModal()"></p-button>
        </div>
      </div>
    </div>
  </form>
</div>