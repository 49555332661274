<div class="form-container">

  <form [formGroup]="group">

    <h2>AGREGAR CONTACTO</h2>
    <div class="inputs">
      <!-- Nombre -->
      <div class="example-full-width-alterno100" style="margin-top: 4%;">
        <span class="p-float-label">
          <input pInputText type="text" formControlName="nombre" placeholder=" " class="p-inputtext-sm">
          <label>Nombre</label>
        </span>
        <small *ngIf="group.get('nombre')?.invalid && group.get('nombre')?.touched" class="p-error">Ingrese un
          nombre</small>
      </div>

      <!-- Apellidos -->
      <div class="example-full-width-alterno100" style="margin-top: 4%;">
        <span class="p-float-label">
          <input pInputText type="text" formControlName="apellido" placeholder=" " class="p-inputtext-sm">
          <label>Apellidos</label>
        </span>
        <small *ngIf="group.get('apellido')?.invalid && group.get('apellido')?.touched" class="p-error">Ingrese un
          apellido</small>
      </div>

      <!-- Documento -->
      <div class="example-full-width-alterno100" style="margin-top: 4%;">
        <span class="p-float-label">
          <input pInputText type="text" formControlName="documento" placeholder=" " class="p-inputtext-sm">
          <label>Documento</label>
        </span>
      </div>

      <!-- Correo -->
      <div class="example-full-width-alterno100" style="margin-top: 4%;">
        <span class="p-float-label">
          <input pInputText type="text" formControlName="correo" placeholder=" " class="p-inputtext-sm">
          <label>Correo</label>
        </span>
      </div>

      <!-- Teléfono 1 -->
      <div class="example-full-width-alterno100" style="margin-top: 4%;">
        <span class="p-float-label">
          <input pInputText type="text" formControlName="telefono1" placeholder=" " class="p-inputtext-sm">
          <label>Teléfono 1</label>
        </span>
        <small *ngIf="group.get('telefono1')?.invalid && group.get('telefono1')?.touched" class="p-error">Ingrese un
          teléfono</small>
      </div>

      <!-- Teléfono 2 -->
      <div class="example-full-width-alterno100" style="margin-top: 4%;">
        <span class="p-float-label">
          <input pInputText type="text" formControlName="telefono2" placeholder=" " class="p-inputtext-sm">
          <label>Teléfono 2</label>
        </span>
      </div>
    </div>

    <div class="a-example-full-width-alterno-content" style="margin-top: 4%;">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <!-- Botón Guardar -->
        <p-button label="GUARDAR" icon="pi pi-check" [disabled]="group.invalid" (onClick)="guardar()"
          class="guardar-button"></p-button>

        <!-- Botón Salir -->
        <p-button label="SALIR" icon="pi pi-times" class="p-button-secondary" (onClick)="closeModal()"></p-button>
      </div>
    </div>


  </form>

</div>