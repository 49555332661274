import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GestionUsuarioRepository } from '../gestion-usuario/domain/gestion-usuario-repository';
import { ListarAccesoRequest, ListarMenuPorRolRequest, ListarMenuPrincipalRequest, ListarMenuRequest, ListarRolesRequest, ListarRolUsuarioRequest, ListarUsuariosRequest, TransaccionAccesoRequest, TransaccionMenuRequest, TransaccionRolesRequest, TransaccionUsuariosRequest } from '../gestion-usuario/domain/request/gestion-usuario-request';
import { ListarAccesoResponse, ListarMenuPorRolResponse, ListarMenuPrincipalResponse, ListarMenuResponse, ListarRolesResponse, ListarRolUsuarioResponse, ListarUsuariosResponse, TransaccionAccesoResponse, TransaccionMenuResponse, TransaccionRolesResponse, TransaccionUsuariosResponse } from '../gestion-usuario/domain/response/gestion-usuario-response';

@Injectable()
export class GestionUsuarioService extends GestionUsuarioRepository {

  constructor(private readonly http: HttpClient
  ) {
    super();
  }
  listarUsuarios(prolrequest: ListarUsuariosRequest): Observable<ListarUsuariosResponse> {
    return this.http.post<ListarUsuariosResponse>(`${environment.PATH_API}/administracion/ListarUsuarios/`, prolrequest);
  }
  transaccionUsuarios(prolrequest: TransaccionUsuariosRequest): Observable<TransaccionUsuariosResponse> {
    return this.http.post<TransaccionUsuariosResponse>(`${environment.PATH_API}/administracion/TransaccionUsuario/`, prolrequest);
  }
  listarRoles(prolrequest: ListarRolesRequest): Observable<ListarRolesResponse> {
    return this.http.post<ListarRolesResponse>(`${environment.PATH_API}/administracion/ListarRoles/`, prolrequest);
  }
  transaccionRoles(prolrequest: TransaccionRolesRequest): Observable<TransaccionRolesResponse> {
    return this.http.post<TransaccionRolesResponse>(`${environment.PATH_API}/administracion/TransaccionRoles/`, prolrequest);
  }
  listarMenus(prolrequest: ListarMenuRequest): Observable<ListarMenuResponse> {
    return this.http.post<ListarMenuResponse>(`${environment.PATH_API}/administracion/ListarMenu/`, prolrequest);
  }
  transaccionMenu(prolrequest: TransaccionMenuRequest): Observable<TransaccionMenuResponse> {
    return this.http.post<TransaccionMenuResponse>(`${environment.PATH_API}/administracion/TransaccionMenu/`, prolrequest);
  }
  listarAcceso(prolrequest: ListarAccesoRequest): Observable<ListarAccesoResponse> {
    return this.http.post<ListarAccesoResponse>(`${environment.PATH_API}/administracion/ListarAcceso/`, prolrequest);
  }

  transaccionAcceso(prolrequest: TransaccionAccesoRequest): Observable<TransaccionAccesoResponse> {
    return this.http.post<TransaccionAccesoResponse>(`${environment.PATH_API}/administracion/TransaccionAccesos/`, prolrequest);
  }
  listarRolUsuario(prolrequest: ListarRolUsuarioRequest): Observable<ListarRolUsuarioResponse> {
    return this.http.post<ListarRolUsuarioResponse>(`${environment.PATH_API}/administracion/ListarRolUsuario/`, prolrequest);
  }
  listarMenuPorRol(prolrequest: ListarMenuPorRolRequest): Observable<ListarMenuPorRolResponse> {
    return this.http.post<ListarMenuPorRolResponse>(`${environment.PATH_API}/administracion/ListarMenuPorRol/`, prolrequest);
  }
  listarMenuPrincipal(prolrequest: ListarMenuPrincipalRequest): Observable<ListarMenuPrincipalResponse> {
    return this.http.post<ListarMenuPrincipalResponse>(`${environment.PATH_API}/administracion/ListarMenuPrincipal/`, prolrequest);
  }
}