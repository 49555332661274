
<div class="general">
        <!-- <p-dialog [visible]="true" [modal]="true" [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false">
            <div style="font-family: 'Roboto', sans-serif; color: #0f4d7e; padding-bottom: 10px;">Guardando...</div>
            <p-progressSpinner></p-progressSpinner>
        </p-dialog> -->
    <div class="progressbar" *ngIf="animate ===1">
        <div style="font-family: 'Roboto', sans-serif; color: #0f4d7e; padding-bottom: 40px;">Guardando...</div>
        <p-progressBar mode="indeterminate" color="#3B82F6" [style]="{ height: '6px' }"></p-progressBar>
    </div>
    <div class="fab-container">
        <button mat-fab class="button1" matTooltip="Salir" matTooltipPosition="left" (click)="salir()">
            <mat-icon>exit_to_app</mat-icon>
        </button>
        <!-- <button mat-raised-button class="button1" (click)="verdato()">Ver Dato</button> -->
    <!-- <button mat-raised-button class="button1" (click)="refresh()">Refrescar data</button> -->
        <button mat-fab class="button1" matTooltip="Guardar" matTooltipPosition="left" (click)="transaccionGantt()">
            <mat-icon>save</mat-icon>
        </button>
        <!-- <button mat-raised-button class="button1" (click)="listarRecursosGantt()">Ver</button> -->
        <button mat-fab class="button1" matTooltip="Exportar Excel" matTooltipPosition="left" (click)="exportEXCEL()">
            <mat-icon>vertical_align_bottom</mat-icon>
        </button>
    </div>
    <br>
    <div class="gantt_control">
        <form [formGroup]="group" style="display: flex; gap: 20px; align-items: center; justify-content: center;">
            <label>Filtrar por fechas:</label>
            <div>
                <span class="p-float-label">
                    <p-calendar formControlName="inicio" [showIcon]="true" dateFormat="dd/mm/yy" class="p-inputtext-sm" (onSelect)="changeDates()"></p-calendar>
                    <label for="float-label">Inicio</label>
                </span>
            </div>
            –
            <div>
                <span class="p-float-label">
                    <p-calendar formControlName="fin" [showIcon]="true" dateFormat="dd/mm/yy" class="p-inputtext-sm" (onSelect)="changeDates()"></p-calendar>
                    <label for="float-label">Fin</label>
                </span>
            </div>
        </form>
    </div>
    <br>
    <div #gantt_here class='gantt-chart'></div>
    <footer>
        <div class="contenido-pie">
          <p></p>
        </div>
    </footer>
</div>


