import { ElementRef, EventEmitter, HostListener, NgZone, Output, ViewChild } from '@angular/core';
import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginUserCU } from 'src/app/usuario/application/user-logincu';
import { StorageService } from 'src/app/services/storage.service';
import { ResponseLogin } from 'src/app/usuario/domain/user-entity';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';


//IMPORT ISMA
import { OverlayContainer } from '@angular/cdk/overlay'
import { MatSidenav } from '@angular/material/sidenav';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { proformaRequest, requestRequisicionesNuevas } from 'src/app/proforma/domain/request/proforma_request';
import { ListaRequisicionesNuevas } from 'src/app/proforma/domain/response/proforma_response';
import { ComisionesRepository } from 'src/app/comisiones/domain/Comisiones-repository';
import { ListarObservacionesStatementRequest } from 'src/app/statement-carrier/domain/request/statement-carrier-request';
import { UtilService } from 'src/app/services/util.service';
import { CorreoAvisoSobrestadiaRequest } from 'src/app/operacion/operacion/control-ovs/domain/control-ovs.request';
import { OperacionRepository } from 'src/app/repositorio/operacion.repository';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isOpen = false;
  name: string;
  n;

  //pruebas DIV
  mostrarDiv = false;
  NotificacionesOpen(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      // cargar notificaciones si es necesario
    }
  }



  hidden = false;
  trigger: any;

  // @HostListener('document:click', ['$event'])
  // ocultarDiv(event: Event): void {
  //   const targetElement = event.target as HTMLElement;

  //   // Verifica si el clic ocurrió dentro del área del div
  //   if (this.miDiv.nativeElement.contains(targetElement)) {
  //     this.isOpen = false;
  //   } else {
  //     this.isOpen = true;
  //   }
  // }

  // @ViewChild('miDivididor') miDiv: ElementRef;

  toggleDiv() {
    // this.isOpen = this.miDiv == undefined ? true : this.miDiv.nativeElement().toggle(); //!this.isOpen;
    this.isOpen = !this.isOpen;
  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }


  @ViewChild('start') startSidenav!: MatSidenav;
  toggleSidenav() {
    this.startSidenav.toggle();
  }

  selected1 = 'light-theme';
  selected = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  nativeSelectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  //Cambios Isma
  @HostBinding('class') componentCssClass: any

  @Output() ResponseLogin = new EventEmitter<ResponseLogin>();

  nombreusuario: string;
  nombreempresa: string;
  descripcionrol: string
  // Sidenav responsive
  width;
  height;
  mode = 'side';
  open = 'true';
  title = 'Responsive Sidenav Starter';
  navList: NavList[];

  constructor(public ngZone: NgZone,
    public route: Router,
    private readonly storage: StorageService,
    private readonly serviciologin: LoginUserCU,
    private readonly auth: AuthService,
    public overlayContainer: OverlayContainer,
    private readonly proformaService: ProformaRepository,
    private readonly comisiones: ComisionesRepository,
    private readonly util: UtilService,
    private readonly router: Router,
    private readonly operacion: OperacionRepository
  ) {
    this.navList = [
      {
        categoryName: 'Menu', icon: 'face', dropDown: true,
        subCategory:
          [
            { subCategoryName: 'Submenu', subCategoryLink: '/link', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/principal', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
          ]
      },
      {
        categoryName: 'Menu', icon: 'face', dropDown: false,
        subCategory:
          [
            { subCategoryName: 'Submenu', subCategoryLink: '/link', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/principal', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
          ]
      },
      {
        categoryName: 'Menu', icon: 'question_answer', dropDown: false,
        subCategory:
          [
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
          ]
      },
      {
        categoryName: 'Menu', icon: 'work', dropDown: false,
        subCategory:
          [
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
            { subCategoryName: 'Submenu', subCategoryLink: '/link1', visable: true, },
          ]
      },
    ];
    this.changeMode();
    window.onresize = (e) => {
      ngZone.run(() => {
        this.changeMode();
      });
    };
    //Lista
  }

  listPrtoO: ListaRequisicionesNuevas[] = [];
  requisiciones: ListaRequisicionesNuevas[] = [];

  listRequisiciones: any[];

  requestDetallePro: ListaRequisicionesNuevas;
  CabecerasNotificaciones: string[] = []
  public cargarnotificaciones() {
    this.listRequisiciones = []
    this.CabecerasNotificaciones = []
    let rol = this.storage.get("rol")

    let correo = this.storage.get("correo")
    const requestRequisicionesNuevas: requestRequisicionesNuevas = <requestRequisicionesNuevas>{};

    this.requestDetallePro = <ListaRequisicionesNuevas>{}

    if (rol == "8") {
      requestRequisicionesNuevas.correousuario = ""
      requestRequisicionesNuevas.estado = "001"
    } else {
      requestRequisicionesNuevas.correousuario = correo
      requestRequisicionesNuevas.estado = "002"
    }


    this.proformaService.requisicionesNuevas(requestRequisicionesNuevas).subscribe(response => {
      if (response.datos.status = 200) {
        this.n = response.datos.result.length;
        // this.requisiciones = response.datos.result

        this.CabecerasNotificaciones = ["cotizacion", "usuario", "f. creacion"]
        //METODO FORMATO
        this.listRequisiciones = response.datos.result.map(registro => {

          const fechaInicio = new Date(registro.audi_feccrea);

          const fechacrea = fechaInicio.toLocaleDateString() + " " + fechaInicio.toLocaleTimeString();

          // Formatear la fecha de fin
          // const fechaFin = new Date(registro.dprO_FechaFin);
          // const fechaFinFormateada = fechaFin.toLocaleDateString();


          return {
            ...registro,
            fechanueva: fechacrea,
          };


        });
      }
    });

    if (rol == '14' || rol == '18' || rol == '16') {
      const request2 = <ListarObservacionesStatementRequest>{}
      request2.usuario = this.storage.get('usuario')
      this.comisiones.ListarObservacionesStatement(request2).subscribe(response => {
        if (response.datos.status = '200') {
          this.CabecerasNotificaciones = ["Via", "Descripción", "fecha"]
          this.listRequisiciones = response.datos.result.map(registro => {

            return {
              ...registro,
              creq_nroreq: registro.nvia_codigo,
              audi_usrcrea: registro.descripcion.replace('.xls', ''),
              fechanueva: this.util.formatearfecha(registro.audi_fecmod, "dd/mm/yyyy")
            };


          });

          this.n = this.listRequisiciones.length
        }
      })
    }

  }



  //VALIDA EL CAMBIO DE COLOR
  public onSetTheme(e: string) {
    if (e != 'dark-theme') {

      this.overlayContainer.getContainerElement().classList.remove("dark-theme")
    } else {

      this.overlayContainer.getContainerElement().classList.add(e);
      this.componentCssClass = e;
    }

    if (e != 'light-theme') {

      this.overlayContainer.getContainerElement().classList.remove("light-theme")
    } else {

      this.overlayContainer.getContainerElement().classList.add(e);
      this.componentCssClass = e;
    }

    if (e != 'vivo-theme') {

      this.overlayContainer.getContainerElement().classList.remove("vivo-theme")
    } else {

      this.overlayContainer.getContainerElement().classList.add(e);
      this.componentCssClass = e;
    }

    if (e != 'tema-claro-dos') {

      this.overlayContainer.getContainerElement().classList.remove("tema-claro-dos")
    } else {

      this.overlayContainer.getContainerElement().classList.add(e);
      this.componentCssClass = e;
    }


    //this.overlayContainer.getContainerElement().classList.add(e);
    //this.componentCssClass = e;
  }


  logout() {

    this.serviciologin.logout();

  }

  responselogin: ResponseLogin
  imagen: string
  ngOnInit() {
    this.nombreusuario = this.storage.get("rol") == '4000' ? ('  ' + this.storage.get("nombreusr")) : ('  ' + this.storage.get("usuario"))
    this.nombreempresa = this.storage.get("compania")
    this.descripcionrol = this.storage.get("descripcionrol")




    var sexo = this.storage.get('sexo')


    if (sexo == null || sexo == 'null') {
      this.imagen = "./../../../../../assets/iconos/male.png";
    } else if (sexo == 'M') {

      this.imagen = "./../../../../../assets/iconos/male.png";
    } else if (sexo == 'F') {

      this.imagen = "./../../../../../assets/iconos/girl.png";
    }

    setTimeout(() => {
      this.cargarnotificaciones();

    }, 1000);

    
    this.correos();
    setInterval(() => {
      this.correos();

    }, 900000);
    // 1800000


  }

  correos() {


    const request = <CorreoAvisoSobrestadiaRequest>{}
    request.usuario = this.storage.get('usuario')
    request.correo = this.storage.get('correo')
    this.operacion.CorreoAvisoSobrestadia(request).subscribe(response => {
    })


  }



  changeMode() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    if (this.width <= 800) {
      this.mode = 'over';
      this.open = 'false';
    }
    if (this.width > 800) {
      this.mode = 'side';
      this.open = 'false';
    }
  }

  buscarstatement(row) {

    let rol = this.storage.get("rol")
    if (rol == '14' || rol == '18' || rol == '16') {
      const codigoviaje = { viaje: row['nvia_codigo'] }
      this.router.navigate(['/opebandejastatement', codigoviaje]);
      this.toggleDiv()
    }

  }


}

export class NavList {
  categoryName: string;
  icon: string;
  dropDown: boolean;
  subCategory: NavListItem[];
  constructor(_categoryName: string, _icon: string, _dropDown: boolean, _subCategory: NavListItem[]) {
    this.categoryName = _categoryName;
    this.icon = _icon;
    this.dropDown = _dropDown;
    this.subCategory = _subCategory;
  }
}

export class NavListItem {
  subCategoryName: string;
  subCategoryLink: string;
  subCategoryQuery?: any;
  visable: boolean;
}
