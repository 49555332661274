import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ListarRecursoRequest, ListarTareasGanttRequest, TransaccionTareaGanttRequest } from '../../domain/request/gestionProyectos-request';
import { GestionProyectosRepository } from '../../domain/gestionProyectos-repository';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { gantt, Task } from 'dhtmlx-gantt'
import { listarRecurso } from '../../domain/response/gestionProyectos-response';
import { FormControl, FormGroup } from '@angular/forms';




@Component({
  selector: 'app-visualizar-gantt',
  templateUrl: './visualizar-gantt.component.html',
  styleUrls: ['./visualizar-gantt.component.css']
})
export class VisualizarGanttComponent {
  constructor(private readonly router: Router,
    private readonly proyectosService: GestionProyectosRepository,
    private readonly storage: StorageService
    // private taskService: TaskService
  ) { }
  salir() {
    this.router.navigate(['/gestionProyectos']);
  }


  public data?: object[];
  public taskSettings?: object;
  @ViewChild('gantt_here', { static: true }) ganttContainer!: ElementRef;


  idproyecto: number
  nomproyecto: string
  datatable: any[] = []
  itemsDelete: any[] = []
  listadoRecursosProyecto: any[] = []
  group: FormGroup

  iniciarFormulario(){
    this.group = new FormGroup({
      inicio: new FormControl(null, null),
      fin: new FormControl(null, null),
    })
  }

  ngOnInit(): void {
    this.iniciarFormulario()
    //Obtener datos de la pagina anterior
    if (localStorage.getItem('row2') != null) {
      const datosSerializados = localStorage.getItem('row2')
      const datos = JSON.parse(datosSerializados);
      localStorage.removeItem('datos')
      console.log(datos)
      this.idproyecto = datos.id
      this.nomproyecto = datos.descripcion_proyecto
    }
    if (localStorage.getItem('recursos') != null) {
      const datosSerializados = localStorage.getItem('recursos')
      const datos = JSON.parse(datosSerializados);
      localStorage.removeItem('datos')
      console.log(datos)
      this.listadoRecursosProyecto = datos
      console.log(this.listadoRecursosProyecto)
    }

    //Tabla recursos
    gantt.serverList("user", this.listadoRecursosProyecto);


    //Tabla Estado
    gantt.serverList("status", [
      { key: 'N', label: "Nuevo" },
      { key: 'P', label: "Planificado" },
      { key: 'E', label: "En curso" },
      { key: 'R', label: "En revision" },
      { key: 'C', label: "Completado" },
      { key: 'S', label: "Stampby" }
    ]);


    //Dias feriados
    var holidays = [
      new Date(2024, 0, 1),  // Año Nuevo
      new Date(2024, 3, 28), // Jueves Santo
      new Date(2024, 3, 29), // Viernes Santo
      new Date(2024, 5, 24), // Fiesta de San Pedro y San Pablo
      new Date(2024, 6, 28), // Fiesta Nacional del Perú
      new Date(2024, 7, 30), // Santa Rosa de Lima
      new Date(2024, 10, 1), // Todos los Santos
      new Date(2024, 11, 8), // Inmaculada Concepción
      new Date(2024, 11, 25) // Navidad
    ];


    //Escala de tiempo de Gantt
    gantt.config.scales = [
      { unit: "month", step: 1, format: "%F, %Y" },
      { unit: "day", step: 1, format: "%d, %M" }
    ];




    //Funcion para devolver el nombre de recurso por su llave
    function byId(list, id) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].key == id)
          return list[i].label || "";
      }
      return "";
    }


    //Activar Horas de Trabajo
    gantt.config.work_time = true;
    gantt.config.skip_off_time = false
    //Ajuste Automático
    gantt.config.autosize = true;
    //Unidad de Duración de las tareas
    gantt.config.duration_unit = "day";
    //Altura de la Escala de Tiempo (Cabecera)
    gantt.config.scale_height = 20 * 3;
    //Altura de las Filas
    gantt.config.row_height = 30;

    gantt.config.open_split_tasks = true;
    //Editores
    var textEditor = { type: "text", map_to: "text" };
    var dateStartEditor = { type: "date", map_to: "start_date", min: new Date(2021, 0, 1), max: new Date(2080, 0, 1), format: "%Y-%m-%d %H:%i:%s" };
    var dateEndEditor = { type: "date", map_to: "end_date", min: new Date(2021, 0, 1), max: new Date(2080, 0, 1), format: "%Y-%m-%d %H:%i:%s" };
    var durationEditor = {
      type: "number", map_to: "duration", min: 0, max: 100
    };
    var resourceEditor = { type: "select", map_to: "users", options: gantt.serverList("user") };
    var statusEditor = { type: "select", map_to: "status", options: gantt.serverList("status") };
    var advanceEditor = { type: "number", map_to: "progress", min: 0, max: 100 };


    //Para manipular las celdas que se editaran y evitar que no se editen otras
    var mapping = {
      init: function (inlineEditors) {
        gantt.attachEvent("onTaskDblClick", function (id, e) { //Se activara el editor de la celda donde se haga doble click
          var cell = inlineEditors.locateCell(e.target);
          if (cell && inlineEditors.getEditorConfig(cell.columnName)) {
            inlineEditors.startEdit(cell.id, cell.columnName);
            return false;
          }
          return true;
        });
        gantt.attachEvent("onEmptyClick", function () { //Se cerrara al hacer click en otra parte
          inlineEditors.hide();
          return true;
        });
      },
      onShow: function (inlineEditors, node) {
        node.onkeydown = function (e) {
          e = e || window.event;
          if (e.defaultPrevented) {
            return;
          }
          var keyboard = gantt.constants.KEY_CODES;
          var shouldPrevent = true;
          console.log('EVENTOS')
          switch (e.keyCode) {
            case gantt.keys.edit_save: //Al presionar Enter
              var cell = inlineEditors.locateCell(e.target); // Obtener la celda activa
              if (cell) {
                let editedValue = inlineEditors.getValue(cell.id, cell.columnName) // Obtener el valor de la celda editada
                console.log('Valor editado:', editedValue);
                var columnName = cell.columnName;
                var task = gantt.getTask(cell.id)
                console.log(task)
                inlineEditors.save({
                  [columnName]: editedValue
                });
              }
              break;
            case gantt.keys.edit_cancel: //Al presionar Escape
              inlineEditors.hide();
              break;
            case keyboard.TAB: //Al presionar TAB
              if (e.shiftKey) {
                inlineEditors.editPrevCell(true);
              } else {
                inlineEditors.editNextCell(true);
              }
              break;
            default:
              shouldPrevent = false;
              break;
          }
          if (shouldPrevent) {
            e.preventDefault();
          }
        };
      },
      onHide: function (inlineEditors, node) { },
      destroy: function () {
      }
    };
    gantt.ext.inlineEditors.setMapping(mapping);




    //Para setear las horas de la fecha final antes de agregar
    gantt.attachEvent("onBeforeTaskAdd", function (id, task) {
      let endDate = new Date(task.end_date);
      endDate.setHours(23, 59, 59, 999);
      task.end_date = endDate;


      return true;
    });


    //Para setear las horas de la fecha final antes de editar
    gantt.attachEvent("onBeforeTaskUpdate", function (id, task) {
      let endDate = new Date(task.end_date)
      // let fechaInicio = new Date(task.start_date);
      // let currentDate = new Date(fechaInicio)
      endDate.setHours(23, 59, 0, 0);
      // let totalDiasLaborales = 0;
      // while (currentDate <= endDate) {
      //   if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      //     totalDiasLaborales++;
      //   }
      //   currentDate.setDate(currentDate.getDate() + 1);
      //   console.log(currentDate)
      // }
      //task.duration = totalDiasLaborales;
      task.end_date = endDate;
      //var task = gantt.getTask(id)
      return true;
    });


    function esDiaLaborable(fecha) {
      const dia = fecha.getDay();
      return dia !== 0 && dia !== 6;
    }
    function sumarDiasLaborables(fechaInicio, dias) {
      let fecha = new Date(fechaInicio);
      let diasSumados = 1;


      while (diasSumados < dias) {
        fecha.setDate(fecha.getDate() + 1);
        if (esDiaLaborable(fecha)) {
          diasSumados++;
        }
      }


      return fecha;
    }
    function calcularDuracion(fechaInicio, fechaFin) {
      let duracion = 0;
      let fecha = new Date(fechaInicio);


      while (fecha <= fechaFin) {
        if (esDiaLaborable(fecha)) {
          duracion++;
        }
        fecha.setDate(fecha.getDate() + 1);
      }


      return duracion;
    }




    //Columnas de la tabla
    gantt.config.columns = [
      { name: "text", label: 'Tarea', tree: true, width: '300', editor: textEditor },
      {
        name: "start_date", label: 'Fecha Inicio', align: "center", width: 85, resize: true, editor: dateStartEditor, template: function (item) {


          let fechaInicio = new Date(item['start_date']);
          fechaInicio.setHours(0, 0, 0, 0);
          return fechaInicio


        }
      },
      {
        name: "end_date", label: 'Fecha Fin', align: "center", width: 85, resize: true, editor: dateEndEditor, template: function (item) {
          if (item['end_date']) {
            // let fechaInicio = new Date(item['start_date']);
            // let duracion = item['duration']
            // item['$calculate_duration'] = false
            // let fechaFin = sumarDiasLaborables(fechaInicio, duracion);
            // console.log(fechaFin)
            // item['end_date'] = fechaFin
            // fechaFin.setHours(23, 59, 0, 0)
            let fechaInicio = new Date(item['start_date']);
            let fechaFin = new Date(item['end_date']);
            item['$calculate_duration'] = false
            const duracion = calcularDuracion(fechaInicio, fechaFin);
            item['duration'] = duracion
            return item['end_date']
          }
          return item['end_date']
        }
      },
      {
        name: "duration", label: 'Duración', align: "center", width: 50, editor: durationEditor, template: function (item) {
          // let fechaInicio = new Date(item['start_date']);
          // let fechaFin = new Date(item['end_date']);
          // item['$calculate_duration'] = false
          // const duracion = calcularDuracion(fechaInicio, fechaFin);
          // item['duration'] = duracion
          let fechaInicio = new Date(item['start_date']);
          let duracion = item['duration']
          item['$calculate_duration'] = false
          let fechaFin = sumarDiasLaborables(fechaInicio, duracion);
          console.log(fechaFin)
          item['end_date'] = fechaFin
          fechaFin.setHours(23, 59, 0, 0)
          //---------------------------
          //   fechaInicio.setHours(0, 0, 0, 0);
          //   fechaFin.setHours(0,0,0,0);
          //   let totalDiasLaborales = 0;


          //   while (fechaInicio <= fechaFin) {
          //     if (fechaInicio.getDay() !== 0 && fechaInicio.getDay() !== 6) {
          //       totalDiasLaborales++;
          //     }
          //     fechaInicio.setDate(fechaInicio.getDate() + 1);
          //   }


          //   item['duration'] = totalDiasLaborales -1;
          return item['duration']
        }
      },
      {
        name: "status", label: "Estado", width: 85, align: "center", editor: statusEditor,
        template: function (item: Task) {
          return byId(gantt.serverList('status'), item.status);
        }
      },
      {
        name: "assigned", label: "Recurso", width: '90', align: "center", resize: true, editor: resourceEditor,
        template: function (item) {
          const userIds = Array.isArray(item.users) ? item.users : [item.users];


          return userIds.map(function (userId) {
            const userName = byId(gantt.serverList('user'), userId);
            return userName ? userName : "Unknown";
          }).join(", ");
        }
      },
      {
        name: "advance", label: "%", width: 50, align: "center", editor: advanceEditor,
        template: function (task) {


          if (task.status === "C") {
            task.progress = 1
            return 100
          }
          if (task.progress <= 1) {
            return Math.floor(task.progress * 100);
          }
          const originalProgress = Math.floor(task.progress);
          task.progress = originalProgress / 100
          return originalProgress;
        }
      },
      { name: "add", width: 30, resize: true, hide: false }
    ];


    //Formato de fecha
    gantt.config.date_format = "%Y-%m-%d %H:%i:%s";


    //Secciones del poppup
    gantt.config.lightbox.sections = [
      { name: "description", height: 38, map_to: "text", type: "textarea", focus: true },
      {
        name: "status", height: 22, map_to: "status", type: "select", options: gantt.serverList('status'),
        onchange: function () {
        }
      },
      {
        name: "assigned", type: "checkbox", map_to: "users", options: gantt.serverList('user'),
        onchange: function () {
        }
      },
      {
        name: "period", type: "time", map_to: "auto", height: 60
      }
    ];




    //Establece nombre a las secciones del poppup
    gantt.locale.labels.section_status = "Estado";
    gantt.locale.labels.section_assigned = "Recurso";
    gantt.locale.labels.section_period = "Periodo de tiempo";

    gantt.config.open_split_tasks = true;
    //Funcion para cambiar icono de los proyectos en caso esten abiertos o cerrados
    gantt.templates.grid_folder = function (task) {
      var icon = task.$open ? "folder_abierto.png" : "folder_cerrado.png";
      return "<img src='../../../../assets/Img/" + icon + "' class='gantt_folder_icon' style='width: 20px; height: 20px; margin-right: 5px; margin-top: 5px;' />";
    };


    //Funcion para cambiar icono de las tareas
    gantt.templates.grid_file = function (task) {
      return "<img src='../../../../assets/Img/tarea.png' class='gantt_file_icon' style='width: 20px; height: 20px; margin-right: 5px; margin-top: 5px;' />";
    };


    //Funcion que devuelve los datos de una tarea eliminada
    gantt.attachEvent("onAfterTaskDelete", (id, item) => {
      console.log("Tarea eliminada:", item);
      this.itemsDelete.push(item)
      console.log(this.itemsDelete)
      this.handleTaskDeletion(item); // Método para manejar la tarea eliminada
    });

    gantt.plugins({ export_api: true,tooltip: true});

    this.listarTareasGantt()
    gantt.init(this.ganttContainer.nativeElement);
  }
  exportEXCEL(){
    gantt.exportToExcel({
      callback: (info: any) => this.showResult(info) 
    })
  }

  private showResult(info: any): void {
    if (!info) {
      gantt.message({
        text: "Server error",
        type: "error",
        expire: -1
      });
    } else {
      gantt.message({
        text: "Presione aquí <a href='" + info.url + "'>export.dhtlmx.com</a>",
        expire: -1
      });
    }
  }

  handleTaskDeletion(task: any) {
    console.log("Datos de la tarea eliminada:", task);
  }


  //Funcion para ver los datos de una tarea agregada
  verdato() {
    const allData = gantt.serialize();
    console.log(allData)
  }


  refresh() { gantt.refreshData() }

  
  changeDates() {
    const values = this.group.value
    const startDateEl = values.inicio;
    const endDateEl = values.fin;
    const startDate = new Date(startDateEl);
    const endDate = new Date(endDateEl);

    if (!+startDate || !+endDate) {
      return
    }
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    gantt.config.start_date = startDate;
    gantt.config.end_date = endDate;
    gantt.render()
  }

  //Funcion para listar datos de la base de datos en la tabla del Gantt
  listarTareasGantt() {
    let data = []
    this.datatable = []
    const links = []
    const resources = []


    const requestTareasGantt: ListarTareasGanttRequest = <ListarTareasGanttRequest>{}
    requestTareasGantt.id_proyecto = this.idproyecto
    this.proyectosService.listarTareasGantt(requestTareasGantt).subscribe(response => {
      if (response.datos.status === 200) {


        response.datos.result.forEach(r => {
          const recursosArray = r.id_recurso
            ? r.id_recurso.split(',').map(id => id.trim())
            : [];


          // Ajustar la fecha de inicio a las 00:00
          const startDate = new Date(r.fecha_iniciotarea);
          startDate.setHours(0, 0, 0, 0);  // Horas: 00:00:00


          // Ajustar la fecha de fin a las 23:59
          const endDate = new Date(r.fecha_fintarea);
          endDate.setHours(23, 59, 59, 999);  // Horas: 23:59:59
          const estado = Number(r.avance); // Suponiendo que r.avance es un string como '0.8'
          const progress = estado < 1 ? estado : 1;


          const item = {
            id: r.nivel,
            text: r.descripcion_tarea,
            start_date: startDate,
            end_date: endDate,
            duration: r.dias,
            parent: r.nivel_padre,
            users: recursosArray,
            status: r.estado,
            progress: progress,
            open:true

          }


          this.datatable.push(item)
        })
        data = this.datatable.slice()
        console.log(data)
        gantt.clearAll();


        // Inicializar nuevamente el Gantt con los nuevos datos
        gantt.init(this.ganttContainer.nativeElement);
        gantt.parse({ data, links, resources });


      }
    })


  }
  recursos: string = ''

  animate = 0
  visible: boolean
  //Funcion para guardar o actualizar los datos del Gantt a la base de datos
  async transaccionGantt() {
    this.animate =  1
    this.visible = true
    const allData = gantt.serialize();
    console.log(allData)
    let count = 0
    if (allData.data) {
      for (const r of allData.data) {
        if (Array.isArray(r.users) && r.users.length > 0) {
          this.recursos = r.users.join(', ');
        } else {
          this.recursos = r.users;
        }
       
        let fechaInicio = new Date(r.start_date);
        let fechaFin = new Date(r.end_date);
        fechaInicio.setHours(0, 0, 0, 0);
        fechaFin.setHours(0, 0, 0, 0);


        let duracion = 0;
        let currentDate = new Date(fechaInicio);


        while (currentDate <= fechaFin) {
          if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) { // Evita sábados y domingos
            duracion++;
          }
          currentDate.setDate(currentDate.getDate() + 1); // Incrementar en un día
        }




        const request = <TransaccionTareaGanttRequest>{};
        request.id_proyecto = this.idproyecto;
        request.descripcio_tarea = r.text;
        request.fecha_inicio = fechaInicio;
        request.fecha_fin = fechaFin;
        request.dias = duracion;
        request.nivel = r.id;
        request.nivel_padre = r.parent;
        request.estado = r.status
        request.id_recurso = this.recursos
        request.usuario = this.storage.get('usuario');
        request.avance = r.progress


        console.log(this.recursos)


        const s = await this.transaccion(request);
        if (s) {
          count++;
        }
        console.log(s);
      }
      this.listarTareasGantt()
    }
    this.animate = 0
    this.visible = false
  }
  deletes() {
    console.log(this.itemsDelete)
  }


  //Funcion que realiza una consulta para cambiar el estado si se consulta de forma correcta
  async transaccion(request: TransaccionTareaGanttRequest) {
    let status = false
    try {
      const response = this.proyectosService.TransaccionTareaGantt(request).toPromise()
      if ((await response).datos.status === 200) { status = true }
    } catch (error) {


    }
    return status
  }
}



