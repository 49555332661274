import { HttpClientModule } from '@angular/common/http';
import { HostListener, Injector } from '@angular/core';
import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { UserRepository } from './usuario/domain/user-repository';
import { UserOperations } from './usuario/infraestructura/user-operation';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    // this.config.zIndex = {
    //   //modal: 1100,    // dialog, sidebar
    //   //overlay: 1000,  // dropdown, overlaypanel
    //   menu: 99999999,     // overlay menus
    //   //tooltip: 1100   // tooltip
    // };

    this.config.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      emptyFilterMessage: 'Sin registros',
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      equals: 'IGUAL A',
      notEquals: 'DIFERENTE DE',
      lt: 'MENOR QUE',
      lte: 'MENOR IGUAL QUE',
      gt: 'MAYOR QUE',
      gte: 'MAYOR IGUAL QUE',
      addRule: 'AGREGAR REGLA',
      clear: 'LIMPIAR',
      apply: 'APLICAR',
      strong: 'Fuerte',
      medium: 'Medio',
      weak: 'Débil',
      passwordPrompt: 'Ingrese una contraseña.',
      contains: 'CONTIENE',
      notContains: 'NO CONTIENE'
      //translations
    });
  }
  userLogged = false;
  title = 'ComercialWeb';
  /*constructor (private readonly injector: Injector){

  const user : any = this.injector.get(UserRepository)//Para llamar instancias que ya estan creadas
  this.userLogged = user.userLogged;
  user.onchangeStatusUser.subscribe((status:boolean) => this.userLogged = status)

  }
  */
  private inactivityTimeout: any; // Corrección del tipo de variable
  ; // Almacena el identificador del temporizador de inactividad
  readonly inactivityDuration = 28800000 // 8 horas//7200000; // Duración de inactividad en milisegundos (30 minutos)

  constructor(private readonly auth: AuthService, private config: PrimeNGConfig) {

    this.resetInactivityTimer()
    this.auth
      .getChangeStatusUser()
      .subscribe((status: boolean) => (this.userLogged = status));



    if (this.userLogged === false) {

      this.userLogged = auth.getListaUseSession()

    }

  }


  // Reinicia el temporizador de inactividad en respuesta a eventos de actividad del usuario
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:keypress', ['$event'])
  resetInactivityTimer() {
    clearTimeout(this.inactivityTimeout);

    // Establece el temporizador para cerrar la sesión después de la duración de inactividad especificada
    this.inactivityTimeout = setTimeout(() => {
      // Lógica para cerrar la sesión aquí (por ejemplo, llamada a la API de cierre de sesión)
      // Puedes mostrar una notificación al usuario antes de cerrar la sesión
      this.auth.logout()
      console.log('Sesión cerrada debido a inactividad.');

    }, this.inactivityDuration);
  }

}
