<div class="a-example-full-width-alterno-content-column">
  <div class=".a-example-full-width-alterno-content">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="container_botones">
      <p-button type="button" class="btn guardar-button" style="margin-left: 10px;" (click)="guardarCotizacion()">
        <i class="pi pi-save" style="margin-right: 8px;"></i> Guardar
      </p-button>
      <p-button type="button" class="btn guardar-button" style="margin-left: 10px;" (click)="Salir('')">
        <i class="pi pi-sign-out" style="margin-right: 8px;"></i> Salir
      </p-button>
    </div>
  </div>
</div>
<div style="margin-top: 1%;"></div>
<div class="a-example-full-width-alterno-content-column">

  <div class="a-example-full-width-alterno-content-row">
    <p-tabView class="a-example-full-width-alterno-content" [(activeIndex)]="activeIndex">

      <p-tabPanel header="Cotización">
        <form [formGroup]="group_tabcotizacion">
          <div style="margin-top: 1%;"></div>
          <div class="a-example-full-width-alterno-content-column-2">
            <div class="a-example-full-width-alterno-content-row">
              <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <input [ngClass]="{ readonly: readOnlyNumeroDGDG }" pInputText formControlName="numeroCotizacion"
                      appendTo="body" />
                    <label>Número</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <input [ngClass]="{ readonly: readOnlyVersionCotizacionDG }" pInputText formControlName="version"
                      appendTo="body" />
                    <label>Versión</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-dropdown [ngClass]="{ readonly: readOnlyEstadoDG }" [options]="ListaTodosEstado" placeholder="‎ "
                      optionLabel="nombre" formControlName="estado" optionValue="codigo" appendTo="body"
                      class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Estado</label>
                  </span>
                </div>
              </div>
              <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                <div class="card a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <input [ngClass]="{ readonly: readOnlyNoCRMDG }" pInputText formControlName="numerocrm"
                      appendTo="body" />
                    <label>No. CRM</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <input [ngClass]="{ readonly: readonlyOrdenCompraDG }" pInputText formControlName="ordencompra"
                      appendTo="body" />
                    <label>Orden Compra</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-dropdown #dropdownRegimen [options]="ListaTodosRegimen" placeholder="‎ " optionLabel="nombre"
                      formControlName="regimen" optionValue="codigo" (onChange)="onRegimenChange($event)"
                      appendTo="body" class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Regimen</label>
                  </span>
                </div>
              </div>
            </div>
            <div class="a-example-full-width-alterno-content-row">
              <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-dropdown [options]="ListaTodosVia" placeholder="‎ " optionLabel="nombre" formControlName="via"
                      optionValue="codigo" (onChange)="onViaChange($event)" appendTo="body" class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Via</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-dropdown [options]="ListaTodosLineaNegocio" placeholder="‎ " optionLabel="nombre"
                      formControlName="lineanegocio" (onChange)="onLineaNegocioChange($event)" optionValue="codigo"
                      appendTo="body" class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Linea negocio</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-dropdown [options]="ListaTodosIncoterm" placeholder="‎ " optionLabel="nombre"
                      formControlName="incoterm" optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Incoterm</label>
                  </span>
                </div>
              </div>
              <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-calendar formControlName="fechaemision" appendTo="body" [showIcon]="true" [showWeek]="false"
                      class="p-inputtext-sm" dateFormat="dd/mm/yy" (onSelect)="onFechaEmiChange($event)"
                      [showOnFocus]="false" [readonlyInput]="false" (onInput)="formatDateInput($event)">
                    </p-calendar>
                    <label for="float-label">F. Emision</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-calendar formControlName="fechavencimiento" appendTo="body" [showIcon]="true" [showWeek]="false"
                      class="p-inputtext-sm" dateFormat="dd/mm/yy" [showOnFocus]="false" [readonlyInput]="false"
                      (onInput)="formatDateInput($event)">
                    </p-calendar>
                    <label for="float-label">F. Vencimiento</label>
                  </span>
                </div>
                <div class="a-example-full-width-alterno-content-4">
                  <span class="p-float-label">
                    <p-calendar formControlName="fechaaprobacion" appendTo="body" [showIcon]="true" [showWeek]="false"
                      dateFormat="dd/mm/yy" class="p-inputtext-sm" [showOnFocus]="false" [readonlyInput]="false"
                      (onInput)="formatDateInput($event)">
                    </p-calendar>
                    <label for="float-label">F. Aprobacion</label>
                  </span>
                </div>
              </div>
            </div>
            <div class="a-example-full-width-alterno-content-row">
              <div class="a-example-full-width-alterno-content-2">
                <span class="p-float-label">
                  <p-dropdown #dropdownRazonSocial [options]="ListaTodosRazonSocial" formControlName="razonSocial"
                    optionLabel="nombre" optionValue="nombre" placeholder="Razón Social" appendTo="body" [filter]="true"
                    [showClear]="true" class="p-inputtext-sm" (onClear)="onClienteClear()" [virtualScroll]="true"
                    (onChange)="onClienteChange($event)" (onHide)="onDropdownHide()">
                  </p-dropdown>
                  <label for="razonSocial">Razón Social</label>
                </span>
              </div>

              <div class="a-example-full-width-alterno-content-2">
                <span class="p-float-label p-input-icon-right">
                  <i class="pi pi-search" (click)="cargaSunat()"></i>
                  <input pInputText formControlName="ruc" placeholder=" " (keydown.enter)="cargaSunat()" />
                  <label>RUC</label>
                </span>
              </div>

            </div>
            <div class="a-example-full-width-alterno-content-row">
              <div class="a-example-full-width-alterno-content-2">
                <span class="p-float-label">
                  <p-dropdown [options]="listContacto" placeholder="‎ " optionLabel="nombre" formControlName="contacto"
                    optionValue="nombre" appendTo="body" [filter]="true" [showClear]="true" class="p-inputtext-sm"
                    dataKey="nombre">
                  </p-dropdown>
                  <label for="float-label">Contacto</label>
                </span>
              </div>
              <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                <div class="card a-example-full-width-alterno-content-4" style="align-items: center;">
                  <p-button icon="pi pi-user" label="Añadir contacto"
                    style="font-size: 30px; width: 100%; display: flex; align-items: center; justify-content: flex-start;"
                    (click)="openModalContacto(contactoComponent, 'contacto')" *ngIf="listaContactoJ.length === 0">
                  </p-button>
                </div>
                <div class="card a-example-full-width-alterno-content-4" style="align-items: center;">
                  <div class="a-checkbox ">
                    <p-checkbox formControlName="cotizacionpropia" [binary]="true" inputId="binary" appendTo="body">
                    </p-checkbox>
                    <label>Cotización Propia</label>
                  </div>
                </div>
                <div class="card a-example-full-width-alterno-content-4" style="align-items: center;">
                  <div class="a-checkbox ">
                    <p-checkbox formControlName="serviciologistico" [binary]="true" inputId="binary" appendTo="body"
                      (onChange)="onServicioLogisticoChange($event)">
                    </p-checkbox>
                    <label>Servicio Logistico</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="a-example-full-width-alterno-content-row">
              <div class="a-example-full-width-alterno-content-2">
                <span class="p-float-label a-example-full-width-alterno-content-2">
                  <p-dropdown [options]="ListaTodosAgentes" placeholder="‎ " optionLabel="nombre"
                    formControlName="agente" optionValue="nombre" appendTo="body" [filter]="true" [showClear]="true"
                    class="p-inputtext-sm" (onChange)="onAgenteChange($event)">
                  </p-dropdown>
                  <label for="float-label">Agente</label>
                </span>
              </div>
              <div class="a-example-full-width-alterno-content-2">
                <span class="p-float-label a-example-full-width-alterno-content-2">
                  <p-dropdown [options]="ListaTodosCustomer" placeholder="‎ " optionLabel="nombre"
                    formControlName="customer" optionValue="nombre" appendTo="body" [filter]="true" [showClear]="true"
                    (onChange)="onCustomerChange($event)" class="p-inputtext-sm">
                  </p-dropdown>
                  <label for="float-label">Customer</label>
                </span>
              </div>
            </div>
            <div class="a-example-full-width-alterno-content-row">

              <div class="a-example-full-width-alterno-content-2">
                <span class="p-float-label a-example-full-width-alterno-content-2">
                  <p-dropdown [options]="ListaTodosEjecutivos" placeholder="‎ " optionLabel="nombre"
                    formControlName="ejecutivo" optionValue="nombre" appendTo="body" [filter]="true" [showClear]="true"
                    (onChange)="onEjecutivoChange($event)" class="p-inputtext-sm">
                  </p-dropdown>
                  <label for="float-label">Ejecutivo</label>
                </span>
              </div>
            </div>
          </div>
        </form>

        <div class=".a-example-full-width-alterno-content" style="margin-top: 1%;">
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <p-button #botonSiguiente type="button" class="btn guardar-button" (click)="onTabDatosGenerales($event)">
              <i class="pi pi-arrow-right" style="margin-right: 8px;"></i> Siguiente
            </p-button>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Datos Generales">
        <p-accordion [multiple]="true">
          <form [formGroup]="group_datosGenerales">

            <p-accordionTab header="Tarifas" [selected]="true">
              <div class="a-example-full-width-alterno-content-column">
                <div class="a-example-full-width-alterno-content-column-2">
                  <div class="a-example-full-width-alterno-content-row">

                    <div class="a-example-full-width-alterno-content-2">
                      <div style="display: flex; align-items: center;">
                        <span class="p-float-label" style="flex: 1;">
                          <p-dropdown #autocompleteTransportista [options]="ListaTodosTransportista" placeholder="‎ "
                            optionLabel="nombre" formControlName="transportista" optionValue="nombre" appendTo="body"
                            [filter]="true" [showClear]="true" class="p-inputtext-sm"
                            (onChange)="onTransporteChange($event)" (onHide)="onDropdownHideTransportista()">
                          </p-dropdown>
                          <label for="float-label">Transportista</label>
                        </span>
                        <p-button *ngIf="mostrarBotonTransportista" type="button" class="btn buscar-button"
                          style="margin-left: 10px;"
                          (click)="visualizarTransportista(transportistasComponent, 'transportista')">
                          <i class="pi pi-user"></i>
                        </p-button>
                      </div>
                    </div>

                    <div class="a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <p-dropdown #dropdownPuertoOrigen [options]="ListaTodosPuertoOrigenyDestino" placeholder="‎ "
                          optionLabel="nombre" formControlName="puertoorigen" optionValue="codigo" appendTo="body"
                          [filter]="true" [showClear]="true" [virtualScroll]="true" class="p-inputtext-sm"
                          (onChange)="onPuertoChange($event)" (onHide)="onDropdownHidePuertoOrigen()">
                        </p-dropdown>
                        <label for="float-label">Puerto Origen</label>
                      </span>
                    </div>

                    <div class="a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <p-dropdown #dropdownPuertoDestino [options]="ListaTodosPuertoOrigenyDestino" placeholder="‎ "
                          optionLabel="nombre" formControlName="puertodestino" optionValue="codigo" appendTo="body"
                          [filter]="true" [showClear]="true" [virtualScroll]="true" class="p-inputtext-sm"
                          (onHide)="onDropdownHidePuertoDestino()">
                        </p-dropdown>
                        <label for="float-label">Puerto Destino</label>
                      </span>
                    </div>

                  </div>

                  <div class="a-example-full-width-alterno-content-column-2">
                    <div class="a-example-full-width-alterno-content-row">
                      <div class="card a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                          <p-dropdown #dropdownContenedor [options]="ListaTipoPaquete" placeholder="‎ "
                            optionLabel="nombre" formControlName="contenedor" optionValue="codigo" appendTo="body"
                            [filter]="true" [showClear]="true" class="p-inputtext-sm"
                            (onChange)="onContenedorChange($event)" (onHide)="onDropdownHideContenedor()">
                          </p-dropdown>
                          <label for="float-label">Contenedor</label>
                        </span>
                      </div>
                      <div class="card a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                          <input pInputText formControlName="cantidad" appendTo="body" min="0" />
                          <label>Cantidad</label>
                        </span>
                      </div>

                      <div class="a-example-full-width-alterno-content-6">
                        <span class="p-float-label">
                          <p-dropdown [options]="ListaTodosMoneda" placeholder="‎ " optionLabel="nombre"
                            formControlName="moneda" optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                          </p-dropdown>
                          <label for="float-label">Moneda</label>
                        </span>
                      </div>

                      <div class="a-example-full-width-alterno-content-6">
                        <span class="p-float-label">
                          <p-dropdown [options]="ListaTodosPuertoOrigenyDestino" placeholder="‎ " optionLabel="nombre"
                            formControlName="puertotransbordo" optionValue="codigo" appendTo="body" [filter]="true"
                            [showClear]="true" [virtualScroll]="true" class="p-inputtext-sm">
                          </p-dropdown>
                          <label for="float-label">Puerto Transbordo</label>
                        </span>
                      </div>


                      <div class="a-example-full-width-alterno-content-6">
                        <span class="p-float-label">
                          <p-dropdown [options]="ListaTodosTrafico" placeholder="‎ " optionLabel="nombre"
                            formControlName="trafico" optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                          </p-dropdown>
                          <label for="float-label">Trafico</label>
                        </span>
                      </div>
                      <div class="a-example-full-width-alterno-content-6">
                        <span class="p-float-label">
                          <p-dropdown [options]="ListaTodosCondicion" placeholder="‎ " optionLabel="nombre"
                            formControlName="condicion" optionValue="codigo" appendTo="body" class="p-inputtext-sm"
                            (onChange)="onCondicionChange($event)">
                          </p-dropdown>
                          <label for="float-label">Condición</label>
                        </span>
                      </div>


                    </div>
                    <div class="a-example-full-width-alterno-content-row">

                      <div class="card a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                          <p-dropdown [options]="ListaTodosTipoFechaTarifa" placeholder="‎ " optionLabel="nombre"
                            formControlName="tipofechatarifa" optionValue="codigo" appendTo="body"
                            class="p-inputtext-sm">
                          </p-dropdown>
                          <label for="float-label">Tipo Fecha Tarifa</label>
                        </span>
                      </div>

                      <div class="card a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                          <p-calendar formControlName="fechatarifa" appendTo="body" [showIcon]="true" [showWeek]="false"
                            class="p-inputtext-sm" dateFormat="dd/mm/yy" [showOnFocus]="false" [readonlyInput]="false"
                            (onInput)="formatDateInput($event)">
                          </p-calendar>
                          <label for="float-label">F. Tarifa</label>
                        </span>
                      </div>


                      <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label" style="flex: 1; display: flex; align-items: center;">
                          <input pInputText formControlName="numerocontrato" appendTo="body" />
                          <label>Número Contrato</label>
                          <p-button #botonContrato type="button" class="btn buscar-button" style="margin-left: 10px;"
                            (click)="buscarContratoAyudaPivot(contratoComponent, 'contrato')">
                            <i class="pi pi-search"></i>
                          </p-button>
                        </span>
                      </div>

                      <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                          <input pInputText formControlName="adicional" appendTo="body" />
                          <label>Descripción</label>
                        </span>
                      </div>

                    </div>

                  </div>

                  <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-0_5">
                      <app-datatable [dataTable]="serviciosytarifas_dataflete" [metadataTable]="metadatableFlete"
                        [rowEdit]="true" [addRow]="true" [rowDelete]="true" [style]="estilos" [title]="'Flete'"
                        [key]="'dcot_item'" (checkboxChange)="onCheckboxChange($event)">
                      </app-datatable>
                    </div>
                    <div class="a-example-full-width-alterno-content-4">
                      <div class="a-example-full-width-alterno-content-column-2">
                        <div class="a-example-full-width-alterno-content-row">
                          <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                              <p-dropdown [options]="ListaTodosPCMBL" placeholder="‎ " optionLabel="nombre"
                                formControlName="pcmbl" optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                              </p-dropdown>
                              <label for="float-label">P/C (MBL)</label>
                            </span>
                          </div>

                          <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                              <p-dropdown [options]="ListaTodosPCHBL" placeholder="‎ " optionLabel="nombre"
                                formControlName="pchbl" optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                              </p-dropdown>
                              <label for="float-label">P/C (HBL)</label>
                            </span>
                          </div>
                        </div>
                        <div class="a-example-full-width-alterno-content-row">
                          <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                              <p-inputNumber formControlName="fletetotal" mode="decimal" [showButtons]="true"
                                [useGrouping]="false" [min]="0" [inputStyle]="{ width: '100%' }">
                              </p-inputNumber>
                              <label for="float-label">Flete Total</label>
                            </span>
                          </div>

                          <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                              <p-inputNumber formControlName="diaslibres" mode="decimal" [showButtons]="true"
                                [useGrouping]="false" [min]="0" [inputStyle]="{ width: '100%' }">
                              </p-inputNumber>
                              <label for="float-label">Dias Libres Sobreestadia</label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </p-accordionTab>

            <p-accordionTab header="Servicios Locales" [selected]="true">
              <form [formGroup]="group_tarifasAdicionales">

                <div style="margin-top: 1%;"></div>
                <div class="a-example-full-width-alterno-content-column">
                  <div class="a-example-full-width-alterno-content-row">
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input pInputText formControlName="codigooperacion"
                          [ngClass]="{ readonly: readOnlyCodigoOperacionDSL }" appendTo="body" />
                        <label>Código operación</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input pInputText formControlName="numerooperacion"
                          [ngClass]="{ readonly: readOnlyNumeroOpeacionDSL }" appendTo="body" />
                        <label>Número operación</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <p-calendar [ngClass]="{ readonly: readonlyFechaEmisionDSL }" formControlName="fechaemision"
                          appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy"
                          (onChange)="onFechaEmiChange($event)" [showOnFocus]="false" [readonlyInput]="false"
                          (onInput)="formatDateInput($event)">
                        </p-calendar>
                        <label>Fecha emisión</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input [ngClass]="{ readonly: readOnlyClienteDSL }" pInputText formControlName="clienteDSL"
                          appendTo="body" />
                        <label>Cliente</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input [ngClass]="{ readonly: readOnlyTransportistaAMDSL }" pInputText
                          formControlName="transportistaam" appendTo="body" />
                        <label>Transportista (A/M)</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <p-dropdown [ngClass]="{ readonly: readOnlyAlmacenDSL }"
                          [ngClass]="{ readonly: readonlyDepositoNov }" [options]="ListaTodosDeposito" placeholder="‎ "
                          optionLabel="nombre" formControlName="deposito" optionValue="nombre" appendTo="body"
                          [filter]="true" [showClear]="true" class="p-inputtext-sm"
                          (onChange)="onDepositoChange($event)">
                        </p-dropdown>
                        <label for="float-label">Almacén</label>
                      </span>
                    </div>
                  </div>

                  <div class="a-example-full-width-alterno-content-row">
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input [ngClass]="{ readonly: readOnlyAgenciaMaritimaDSL }" pInputText formControlName="agencia"
                          appendTo="body" />
                        <label>Agencia marítima</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input [ngClass]="{ readonly: readOnlyTerminalPortuarioDSL }" pInputText
                          formControlName="terminal" appendTo="body" />
                        <label>Terminal portuario</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input [ngClass]="{ readonly: readOnlyCondicionEmbarqueDSL }" pInputText
                          formControlName="condicion" appendTo="body" />
                        <label>Condición embarque</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <p-dropdown [options]="ListaTodosTransportista" placeholder="‎ " optionLabel="nombre"
                          formControlName="transportistaTerre" optionValue="codigo" appendTo="body" [filter]="true"
                          [showClear]="true" class="p-inputtext-sm" (onChange)="onTransTerrChange($event)">
                        </p-dropdown>
                        <label for="float-label">Transportista (T)</label>
                      </span>
                    </div>

                    <div class="InputSwitch a-example-full-width-alterno-content-3">
                      <p-inputSwitch [ngClass]="{ readonly: readonlySADADSL }" formControlName="sada" />SADA
                    </div>

                    <div class="InputSwitch a-example-full-width-alterno-content-3">
                      <p-inputSwitch [ngClass]="{ readonly: readonlyIMODSL }" formControlName="imo" />IMO
                    </div>
                  </div>

                  <div class="a-example-full-width-alterno-content-row">

                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <input pInputText formControlName="tipo" appendTo="body" />
                        <label>Tipo</label>
                      </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <p-calendar formControlName="vigenciadesde" appendTo="body" [showIcon]="true" [showWeek]="false"
                          dateFormat="dd/mm/yy" [showOnFocus]="false" [readonlyInput]="false"
                          (onInput)="formatDateInput($event)">
                        </p-calendar>
                        <label for="float-label">Vigencia desde</label>
                      </span>
                    </div>

                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                      <span class="p-float-label">
                        <p-calendar formControlName="vigenciahasta" appendTo="body" [showIcon]="true" [showWeek]="false"
                          dateFormat="dd/mm/yy" [showOnFocus]="false" [readonlyInput]="false"
                          (onInput)="formatDateInput($event)">
                        </p-calendar>
                        <label for="float-label">Vigencia hasta</label>
                      </span>
                    </div>
                  </div>

                  <div class="a-example-full-width-alterno-content-row">
                    <p-tabView class="a-example-full-width-alterno-content">

                      <p-tabPanel header="Tarifas">
                        <div class="a-example-full-width-alterno-content" style="position: relative;">
                          <app-datatable [dataTable]="servicioslocales_tarifa" [metadataTable]="metadatableTarifa"
                            [filter]="true" [filterGlobal]="true" [paginacion]="false" (Row)="row = $event"
                            (checkboxChange)="onCheckboxChangeTarifas($event)" [customButtons]="custombTarifas">
                          </app-datatable>
                        </div>
                      </p-tabPanel>
                      <p-tabPanel header="Descripción">
                        <textarea [ngClass]="{ readonly: readonlyDescripcionDSL }" id="float-input" rows="5" cols="30"
                          pInputTextarea formControlName="descripcion" style="width: 100%;"></textarea>
                      </p-tabPanel>
                      <p-tabPanel header="Observación">
                        <div class="a-example-full-width-alterno-content">
                          <app-datatable [dataTable]="servicioslocales_dataObservaciones"
                            [metadataTable]="metadatableDataObservaciones" [filter]="true">
                          </app-datatable>
                        </div>
                      </p-tabPanel>
                    </p-tabView>
                  </div>
                </div>

                <div style="margin-top: 1%;"></div>
                <div class="a-example-full-width-alterno-content-column">
                  <div class="a-example-full-width-alterno-content">
                    <app-datatable [dataTable]="servicioslocales_datadetalle" [metadataTable]="metadatableDataDetalle"
                      [blockedColumns]="blockedColumnsSL" [rowEdit]="true" [rowDelete]="true" [key]="'dope_item'">
                    </app-datatable>
                  </div>
                </div>

              </form>
            </p-accordionTab>

            <p-accordionTab header="Servicios" [selected]="true">
              <div class="a-example-full-width-alterno-content-column">
                <div class="a-example-full-width-alterno-content-column-2">
                  <div class="a-example-full-width-alterno-content">
                    <app-datatable [dataTable]="serviciosytarifas_dataservicios" [metadataTable]="metadatableServicio"
                      [addRow]="true" [rowEdit]="true" [rowDelete]="true" [style]="estilos" [title]="'Servicios'"
                      [key]="'scot_item'">
                    </app-datatable>
                  </div>
                </div>

              </div>
            </p-accordionTab>

            <form [formGroup]="group_datosAdicionales">


              <p-accordionTab header="Datos Embarque" [selected]="true">

                <div class="a-example-full-width-alterno-content-column">

                  <p-fieldset legend="Datos de contrato" class="a-example-full-width-alterno-content">
                    <div class="a-example-full-width-alterno-content-column">
                      <div class="a-example-full-width-alterno-content-row">
                        <div class="a-checkbox a-example-full-width-alterno-content-3">
                          <p-checkbox formControlName="cargarefrigerada" [binary]="true" inputId="binary"
                            appendTo="body">
                          </p-checkbox>
                          <label>Carga Refrigerada</label>
                        </div>

                        <div class="a-example-full-width-alterno-content-3">
                          <span class="p-float-label">
                            <p-inputNumber formControlName="temperatura" mode="decimal" [showButtons]="true"
                              [useGrouping]="false" [inputStyle]="{ width: '100%' }">
                            </p-inputNumber>
                            <label for="float-label">Temperatura (C°)</label>
                          </span>
                        </div>

                        <div class="a-checkbox a-example-full-width-alterno-content-3">
                          <p-checkbox formControlName="cargapeligrosa" [binary]="true" inputId="binary" appendTo="body"
                            (onChange)="onCargaPeligrosaChange($event)">
                          </p-checkbox>
                          <label>Carga Peligrosa</label>
                        </div>

                        <div class="a-example-full-width-alterno-content-4">
                          <span class="p-float-label">
                            <p-dropdown [ngClass]="{ readonly: readonlyImoClassDE }" [options]="ListaTodosImoClass"
                              placeholder="‎ " optionLabel="nombre" formControlName="imoclass" optionValue="codigo"
                              appendTo="body" class="p-inputtext-sm" font-size="8px">
                            </p-dropdown>
                            <label for="float-label">IMO Class</label>
                          </span>
                        </div>

                        <div class="a-checkbox a-example-full-width-alterno-content-3">
                          <p-checkbox formControlName="roundtrip" [binary]="true" inputId="binary" appendTo="body">
                          </p-checkbox>
                          <label>RoundTrip</label>
                        </div>

                        <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                          <span class="p-float-label">
                            <input pInputText formControlName="imoun" appendTo="body"
                              [ngClass]="{ readonly: readOnlyImoUnDE }" />
                            <label>IMO UN</label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </p-fieldset>

                  <p-fieldset legend="Datos adicionales" class="a-example-full-width-alterno-content">
                    <div class="a-example-full-width-alterno-content-column a-example-full-width-alterno-content">

                      <div class="a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content">
                          <span class="p-float-label">
                            <p-dropdown [options]="ListaTodosShipper" placeholder="‎ " optionLabel="nombre"
                              formControlName="shipper" optionValue="nombre" appendTo="body" [filter]="true"
                              [showClear]="true" class="p-inputtext-sm" [virtualScroll]="true"
                              (onChange)="onShipperChange($event)">
                            </p-dropdown>
                            <label for="float-label">Shipper</label>
                          </span>
                        </div>
                      </div>
                      <div class="a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content">
                          <span class="p-float-label">
                            <p-dropdown [options]="ListaTodosConsignee" placeholder="‎ " optionLabel="nombre"
                              formControlName="consignee" optionValue="nombre" appendTo="body" [filter]="true"
                              [showClear]="true" class="p-inputtext-sm" [virtualScroll]="true"
                              (onChange)="onConsigneeChange($event)">
                            </p-dropdown>
                            <label for="float-label">Consignee</label>
                          </span>
                        </div>
                      </div>
                      <div class="a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content">
                          <span class="p-float-label">
                            <p-dropdown [options]="ListaTodosNotify" placeholder="‎ " optionLabel="nombre"
                              formControlName="notify" optionValue="nombre" appendTo="body" [filter]="true"
                              [showClear]="true" class="p-inputtext-sm" [virtualScroll]="true"
                              (onChange)="onNotifyChange($event)">
                            </p-dropdown>
                            <label for="float-label">Notify</label>
                          </span>
                        </div>
                      </div>
                      <div class="a-example-full-width-alterno-content-row">
                        <div class="card flex justify-content-center a-example-full-width-alterno-content">
                          <span class="p-float-label">
                            <input pInputText formControlName="referencia" appendTo="body" />
                            <label>Referencia</label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </p-fieldset>
                </div>
                <div class="titulo_secciones">Flete</div>
                <div class="a-example-full-width-alterno-content">
                  <app-datatable [dataTable]="datosembarque_dataflete" [metadataTable]="metadatableFleteEmbarque"
                    [filter]="true" [addRow]="true" [rowEdit]="true" [style]="estilos" [title]="' '"
                    [key]="'dcot_item'">
                  </app-datatable>
                </div>

              </p-accordionTab>
              <p-accordionTab header="Observaciones" [selected]="true">

                <div class="a-example-full-width-alterno-content-column">
                  <div class="a-example-full-width-alterno-content">
                    <div class="a-example-full-width-alterno-content-row">
                      <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                          <p-inputNumber formControlName="almacenaje" mode="decimal" [showButtons]="true"
                            [useGrouping]="false" [inputStyle]="{ width: '100%' }">
                          </p-inputNumber>
                          <label for="float-label">Almacenaje (días)</label>
                        </span>
                      </div>

                      <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                          <p-inputNumber formControlName="tiempoviaje" mode="decimal" [showButtons]="true"
                            [useGrouping]="false" [inputStyle]="{ width: '100%' }">
                          </p-inputNumber>
                          <label for="float-label">Tiempo viaje (dias)</label>
                        </span>
                      </div>
                      <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                          <p-inputNumber formControlName="sobreestadia" mode="decimal" [showButtons]="true"
                            [useGrouping]="false" [inputStyle]="{ width: '100%' }">
                          </p-inputNumber>
                          <label for="float-label">Sobreestadia (días)</label>
                        </span>
                      </div>

                      <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                          <p-inputNumber formControlName="validezoferta" mode="decimal" [showButtons]="true"
                            [useGrouping]="false" [inputStyle]="{ width: '100%' }">
                          </p-inputNumber>
                          <label for="float-label">Validez oferta (dias)</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="a-example-full-width-alterno-content">
                    <div class="a-example-full-width-alterno-content-row">
                      <span class="p-float-label a-example-full-width-alterno-content-row">
                        <textarea id="float-input" rows="5" cols="30" pInputTextarea formControlName="instrucciones"
                          style="width: 100%"></textarea>
                        <label for="float-label">Instrucciones de embarque</label>
                      </span>
                    </div>
                  </div>
                </div>

              </p-accordionTab>

            </form>

          </form>

        </p-accordion>

      </p-tabPanel>

    </p-tabView>

  </div>
</div>
<footer>
  <div class="contenido-pie">
    <p></p>
  </div>
</footer>