import { Observable } from "rxjs/internal/Observable";
import { ListarClientesHijosRequest, ListarTodosWebRequest, Request_Cotizacion, TransaccionClientesHijosRequest, ValidarProfitRequest, actualizaCliente, contactoRequest, contenedorRequest, guardaRequisisionesRequest, imoRequest, incoterRequest, insertaCienteRequest, listaClienteRequest, listaProformaInternaRequest, mantProformaRequest, proformaDetalleRequest, proformaGuardaRequest, proformaRequest, proformaupdate, puertoRequest, requestAlmacen, requestBultos, requestCambiarContra, requestCierraCotizacion, requestClientesEfectivos, requestContenedorTerminar, requestDepartamento, requestDistrito, requestDistritosZonas, requestExisteUsuario, requestGenerico, requestProvincia, requestRequisicionesNuevas, requestTransportista, request_serviciolocal, requestcontacto, requestejecutivo, requestimagen, requestnaves, requestservicios, requestservicioslocales, requestupdatecliente, rucInternoRequest, sunatRequest, tarifaPromocionRequest, tarifaRequest, } from "./request/proforma_request"
import { ActualizaClienteResponse, CambiaContraResponse, CierraCotizacionResponse, ClienteResponse2, ClientesEfectivosResponse, ContactoResponse, ContenedorResponse, ContenedorTerminarResponse, DistritosZonasResponse, ExisteUsuarioResponse, ExportarClientesHijosResponse, GuardaRequisisionesResponse, GuardaResponse, ImoResponse, IncoterResponse, InsertaClienteResponse, ListaReporteExcel, ListaTipoContenedorResponse, ListarClientesHijosResponse, ListarTodosWebResponse, ProformaDetalleResponse, ProformaInternaArchivosDescargaResponse, ProformaInternaArchivosResponse, ProformaInternaBultosResponse, ProformaInternaCostoResponse, ProformaInternaGastoResponse, ProformaInternaResponse, ProformaMantResponse, ProformaResponse, PuertoResponse, ReporteExcelResponse, RequisicionesNuevasResponse, ResponseContacto, RucInternoPromocionResponse, RucInternoResponse, SunatResponse, TarifaResponse, TipoContenedorResponse, TransaccionCliHijosResponse, TransportistaResponse, ValidarProfitResponse, clientesservicioslocales, responseAlmacen, responseDepartamento, responseDistrito, responseProvincia, response_clienteupdate, response_serviciolocales, responseejecutivo, responsegastoslocales, responseimagen, responseinsertarcotizacion, responselistadonaves, responseproformaupdate, responseservicio, validacionTransaccionResponse } from "./response/proforma_response";
import { requestrequerimiento } from "src/app/requerimiento/domain/request/request_requerimiento";
import { responserequerimientodet } from "src/app/requerimiento/domain/response/response_requerimiento";
import { AvanceVentasRequest, ProyeccionCuotasRequest, requestejecutivosanualizados } from "src/app/proyeccion-cuotas/domain/request/reporte-request";
import { ListarAnioResponse, ListarMesResponse, ProyeccionCuotasResponse, ProyeccionVisitasResponse } from "src/app/proyeccion-cuotas/domain/response/reporte_response";
import { ListaAvanceVentasResponse, ListaCotizacionesCerradas, ListaCotizacionesCerradasResponse, ListaReporteEjecutivoAnualizadosResponse, PricingAtendidoResponse } from "src/app/efectividad-ventas/domain/response/efectividad-ventas-response";
import { ProyeccionVisitasRequest } from "src/app/efectividad-visitas/domain/request/reporte-efectividad-visitas-request";
import { ListarEfectividadVisitasResponse } from "src/app/efectividad-visitas/domain/response/reporte-efectividadVisitas-response";
import { requestClientesRecuperados, requestPricingAtendido } from "src/app/dashboardVentas/domain/request/dashboard.request";
import { requestCotiCerrada } from "src/app/reporte-cotizaciones-cerradas_v2/domain/request/request-coti-cerrada";
import { RCotiCerradaResponse } from "src/app/reporte-cotizaciones-cerradas_v2/domain/response/response-coti-cerrada";
import { seguimientoRutaGeotrackRequest, seguimientoRutaRequest } from "src/app/seguimientoRuta/domain/request/seguimientoRutaRequest";
import { seguimientoRutaGeoResponse, seguimientoRutaGeotrackResponse, seguimientoRutaResponse } from "src/app/seguimientoRuta/domain/response/seguimientoRutaResponse";
import { ClientesRecuperadosResponse, ListaPricingResumenResponse } from "src/app/dashboardVentas/domain/response/dashboard.response";
import { EjemploResponse } from '../../ejemplo/domain/response/ejemploresponse';
import { EjemploRequest } from '../../ejemplo/domain/request/ejemplorequest';
import { requestGuardaBL, requestListaTipoContenedor, requestOCR, requestOCR_HAPAG, requestOCR_Hojas, requestOCR_Hojas_HAPAG, requestTipoContenedor, requestTransaccionValidacion } from "src/app/ocr_pdf_2/domain/request/ocr_pdf.request";
import { responseOCR, responseOCRHojas, responseOCR_HAPAG, responseOCR_descripcion_hapag } from "src/app/ocr_pdf_2/domain/response/ocr_pdf.response";
import { response_guarda_servicio, response_servicios, response_servicios_conceptos } from "src/app/mantenimiento_servicios/domain/response/response_servicios.response";
import { registra_servicios_request } from "src/app/mantenimiento_servicios/domain/request/agrega_servicios.request";
// import { ListarTodosWebResponse } from "src/app/comercial-orden-venta/domain/response/comercial-orden-venta-response";
// import { ListarTodosWebRequest } from "src/app/comercial-orden-venta/domain/request/comercial-orden-venta-request";

export abstract class ProformaRepository {

  //abstract insert( fd : ParteEntity):Observable<ParteInsertResponse>;
  abstract listar(fd: proformaRequest): Observable<ProformaResponse>;

  abstract listarSunat(fd: sunatRequest): Observable<SunatResponse>;
  abstract listarSunat2(fd: sunatRequest): Observable<SunatResponse>;
  abstract listarRucInterno(fd: rucInternoRequest): Observable<RucInternoResponse>;

  //RUC INTERNO PROMOCION
  abstract listarRucInternoPromocion(fd: rucInternoRequest): Observable<RucInternoPromocionResponse>;

  abstract listarPuerto(fd: puertoRequest): Observable<PuertoResponse>;
  abstract listarIncoter(fd: incoterRequest): Observable<IncoterResponse>;
  abstract listarContenedor(fd: contenedorRequest): Observable<ContenedorResponse>;
  abstract listarTarifa(fd: tarifaRequest): Observable<TarifaResponse>;
  //TARIFA PROMOCION
  abstract listarTarifaPromocion(fd: tarifaPromocionRequest): Observable<TarifaResponse>;
  abstract guardarProforma(fd: proformaGuardaRequest): Observable<GuardaResponse>;
  abstract mantProforma(fd: mantProformaRequest): Observable<ProformaMantResponse>;
  abstract detalleProforma(fd: proformaDetalleRequest): Observable<ProformaDetalleResponse>;

  //PROMOCION
  abstract detalleProformaPromocion(fd: proformaDetalleRequest): Observable<ProformaDetalleResponse>;

  abstract listaContacto(fd: contactoRequest): Observable<ContactoResponse>;
  abstract listaImo(fd: imoRequest): Observable<ImoResponse>;
  abstract guardaRequisisiones(fd: guardaRequisisionesRequest): Observable<GuardaRequisisionesResponse>;
  abstract listaProformaInterna(fd: listaProformaInternaRequest): Observable<ProformaInternaResponse>;
  abstract listaCliente(fd: listaClienteRequest): Observable<ClienteResponse2>
  abstract insertaCliente(fd: insertaCienteRequest): Observable<InsertaClienteResponse>
  abstract insertarcotizacion(prolrequest: Request_Cotizacion): Observable<responseinsertarcotizacion>
  abstract updateproforma(prolrequest: proformaupdate): Observable<responseproformaupdate>
  abstract listarequerimientodetalle(prolrequest: requestrequerimiento): Observable<responserequerimientodet>;
  abstract actualizaCliente(fd: actualizaCliente): Observable<ActualizaClienteResponse>;
  abstract mancotacto(prolrequest: requestcontacto): Observable<ResponseContacto>;
  abstract listarcontacto(prolrequest: requestcontacto): Observable<ResponseContacto>;
  abstract listarserviciolocales(prolrequest: request_serviciolocal): Observable<response_serviciolocales>;
  abstract listarserviciolocalesPromocion(prolrequest: request_serviciolocal): Observable<response_serviciolocales>;
  abstract listadoproformadetservlocales(prolrequest: proformaDetalleRequest): Observable<response_serviciolocales>;

  //promocion
  // abstract listadoproformadetservlocalespromocion(prolrequest: proformaDetalleRequest): Observable<response_serviciolocales>;

  abstract listardepartamentos(prolrequest: requestDepartamento): Observable<responseDepartamento>;
  abstract listarprovincias(prolrequest: requestProvincia): Observable<responseProvincia>;
  abstract listardistritos(prolrequest: requestDistrito): Observable<responseDistrito>;

  abstract listaTransportista(prolrequest: requestTransportista): Observable<TransportistaResponse>

  abstract listaAlmacen(prolrequest: requestAlmacen): Observable<responseAlmacen>

  abstract listaProformaInternaCosto(prolrequest: requestGenerico): Observable<ProformaInternaCostoResponse>

  abstract listaProformaInternaGasto(prolrequest: requestGenerico): Observable<ProformaInternaGastoResponse>

  abstract listaProformaInternaBultos(prolrequest: requestBultos): Observable<ProformaInternaBultosResponse>


  abstract updatecliente(prolrequest: requestupdatecliente): Observable<response_clienteupdate>;

  abstract listarnaves(prolrequest: requestnaves): Observable<responselistadonaves>

  abstract listaProyeccionCuotas(prolrequest: ProyeccionCuotasRequest): Observable<ProyeccionCuotasResponse>
  abstract listaProyeccionVisitas(prolrequest: ProyeccionCuotasRequest): Observable<ProyeccionVisitasResponse>
  abstract listaCotizacionesCerradas(prolrequest: ProyeccionCuotasRequest): Observable<ListaCotizacionesCerradasResponse>
  abstract listaAvanceVentas(prolrequest: AvanceVentasRequest): Observable<ListaAvanceVentasResponse>
  abstract listaReporteExcel(prolrequest: AvanceVentasRequest): Observable<ReporteExcelResponse>

  abstract listaPricingAtendido(prolrequest: requestPricingAtendido): Observable<PricingAtendidoResponse>


  abstract ListarMes(prolrequest: ''): Observable<ListarMesResponse>
  abstract ListarAnio(prolrequest: ''): Observable<ListarAnioResponse>

  abstract listaEfectividadVisitas(prolrequest: ProyeccionVisitasRequest): Observable<ListarEfectividadVisitasResponse>;

  abstract subirarchivo(prolrequest: FormData): Observable<responseimagen>;

  abstract listaProformaInternaArchivos(prolrequest: requestBultos): Observable<ProformaInternaArchivosResponse>;

  abstract listaProformaInternaArchivosDescarga(prolrequest: requestBultos): Observable<ProformaInternaArchivosDescargaResponse>;

  abstract listarejecutivo(prolrequest: requestejecutivo): Observable<responseejecutivo>;

  abstract listarservicio(prolrequest: requestservicios): Observable<responseservicio>;
  //promociones
  abstract listarservicioZonaPromocion(prolrequest: requestservicios): Observable<responseservicio>;

  abstract listadoproformadetgastoslocales(prolrequest: proformaDetalleRequest): Observable<responsegastoslocales>


  abstract listaClientesEfectivos(prolrequest: requestClientesEfectivos): Observable<ClientesEfectivosResponse>;
  abstract listaUsuarioExiste(prolrequest: requestExisteUsuario): Observable<ExisteUsuarioResponse>
  abstract cambiaContrasena(prolrequest: requestCambiarContra): Observable<CambiaContraResponse>
  abstract requisicionesNuevas(prolrequest: requestRequisicionesNuevas): Observable<RequisicionesNuevasResponse>

  abstract clientesservicioslocales(prolrequest: requestservicioslocales): Observable<clientesservicioslocales>
  abstract cierraCotizaciones(prolrequest: requestCierraCotizacion): Observable<CierraCotizacionResponse>

  //REPORTE COTIZACION CERRADA
  abstract reporteCotizacionCerrada(prolrequest: requestCotiCerrada): Observable<RCotiCerradaResponse>
  abstract seguimientoRuta(prolrequest: seguimientoRutaRequest): Observable<seguimientoRutaResponse>
  abstract seguimientoRutaGeotrack(prolrequest: seguimientoRutaGeotrackRequest): Observable<seguimientoRutaGeotrackResponse>
  abstract seguimientoRutaGeo(): Observable<seguimientoRutaGeoResponse>


  abstract listaAvanceVentas_otros(prolrequest: AvanceVentasRequest): Observable<ListaAvanceVentasResponse>

  abstract listaEjecutivosAnualizados(prolrequest: requestejecutivosanualizados): Observable<ListaReporteEjecutivoAnualizadosResponse>

  abstract ValidarCreacionForecast(prolrequest: ValidarProfitRequest): Observable<ValidarProfitResponse>

  abstract listarDatos(prolrequest: EjemploRequest): Observable<EjemploResponse>


  //esto es de operaciones -----------------------------------------------------
  abstract procesaOCR(prolrequest: requestOCR): Observable<responseOCR>
  abstract procesaOCR_Descripcion_HAPAG(prolrequest: requestOCR_Hojas_HAPAG): Observable<responseOCR_descripcion_hapag>
  abstract procesaOCR_HAPAG(prolrequest: requestOCR_HAPAG): Observable<responseOCR_HAPAG>
  abstract procesaOCR_Hojas(prolrequest: requestOCR_Hojas): Observable<responseOCRHojas>
  abstract guardaTipoContenedorPDF(prolrequest: requestTipoContenedor): Observable<TipoContenedorResponse>
  abstract guardaTransaccionValidacionPDF(prolrequest: requestTransaccionValidacion): Observable<validacionTransaccionResponse>
  abstract guarda_BL_PDF(prolrequest: requestGuardaBL): Observable<validacionTransaccionResponse>
  abstract listaContenedorTerminar(prolrequest: requestContenedorTerminar): Observable<ContenedorTerminarResponse>
  abstract listaDistritosZonas(prolrequest: requestDistritosZonas): Observable<DistritosZonasResponse>



  //LISTAR TARIFA SERVICIOS LOCALES PROMOCION
  abstract listarTarifaServiciosLocalesPromocion(prolrequest: requestDistritosZonas): Observable<DistritosZonasResponse>
  abstract listarTipoContenedor(prolrequest: requestListaTipoContenedor): Observable<ListaTipoContenedorResponse>
  abstract listaServiciosOperaciones(): Observable<response_servicios>
  abstract listaServiciosConceptos(): Observable<response_servicios_conceptos>
  abstract guardaServicioOperaciones(prolrequest: registra_servicios_request): Observable<response_guarda_servicio>

  abstract ListarTodosWeb(prolrequest: ListarTodosWebRequest): Observable<ListarTodosWebResponse>;
  abstract ListarClientesHijos(prolrequest: ListarClientesHijosRequest): Observable<ListarClientesHijosResponse>;
  abstract TransaccionClientesHijos(prolrequest: TransaccionClientesHijosRequest): Observable<TransaccionCliHijosResponse>;
  abstract exportCliHijos(prolrequest: ListarClientesHijosRequest): Observable<ExportarClientesHijosResponse>;
}
