<div class="a-title barra-titulo">
    <div>{{cliente.ruc_cliente + ' - ' + cliente.cliente}}</div>
    <div (click)="close()" style="cursor: pointer;">CERRAR</div>
</div>
<p-tabView>
    <p-tabPanel header="AGENTE DE CARGA">
        <div class="a-w1-column">
            <app-datatable [metadataTable]="rankingAgenteMDT" [dataTable]="DateTable">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changeLine($event,row)" style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="lineaRankingAgenteMDT" [dataTable]="lineaAgenteDT"></app-datatable>
            <!-- <app-datatable [metadataTable]="embarcacionesLineaRankingAgenteMDT" [dataTable]="lineaAgenteDT"></app-datatable> -->
        </div>
    </p-tabPanel>
    <p-tabPanel header="PARTIDAS ARANCELARIAS">
        <div class="a-w1-column">
            <app-datatable [metadataTable]="partidasMDT" [dataTable]="partidasDT">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changePartida($event,row)"
                                style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="partidasArancelariasMDT" [dataTable]="lineaPartidaDT"></app-datatable>
        </div>
    </p-tabPanel>
</p-tabView>