import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportesRepository } from 'src/app/reporte-ventas/domain/reportes.repository';
import { ItemsReporteCarteraEjecutivoDetalle, ItemsReporteCarteraEjecutivoPartidas, ReporteCarteraEjecutivoPartidasResponse } from '../domain/reporte-cartera-ejecutivo.response';
import { ItemsReporteFletePorLineaDetalleResponse, ItemsReporteFletePorLineaResponse, ItemsReporteKunaqPorImportadorTipoResponse } from 'src/app/reporte-ventas/domain/response/reporte-ventas-response';
import { ReporteKunaqPorImportadorRequest } from 'src/app/reporte-ventas/domain/request/reporte-ventas-request';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ReporteCarteraEjecutivoRequest } from '../domain/reporte-cartera-ejecutivo.request';

@Component({
  selector: 'app-detalle-cliente-ejecutivo',
  templateUrl: './detalle-cliente-ejecutivo.component.html',
  styleUrls: ['./detalle-cliente-ejecutivo.component.css']
})
export class DetalleClienteEjecutivoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public cliente: ItemsReporteCarteraEjecutivoDetalle,
    private readonly reference: MatDialogRef<DetalleClienteEjecutivoComponent>,
    private readonly servReportes: ReportesRepository
  ) { }

  ngOnInit(): void {
    this.search()
    this.searchSecondTab()
  }

  rankingAgenteMDT: newmetadate[] = [
    { field: 'item', title: 'TOP', inputType: 'text' },
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
  //  { field: 'porcentaje', title: '%', inputType: 'percentage', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' },
  ]

  lineaRankingAgenteMDT: newmetadate[] = [
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' },
  ]

  
  embarcacionesLineaRankingAgenteMDT: newmetadate[] = [
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'partida', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
    { field: 'cnt20', title: 'CNT 20', inputType: 'int', rowFooter: 'sum' },
    { field: 'cnt40', title: 'CNT 40', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' },
  ]

  DateTable: ItemsReporteFletePorLineaResponse[] = []
  listDetalleCliente: any[] = []
  ListaItemsRanking: ItemsReporteKunaqPorImportadorTipoResponse[] = []
  search() {
    const request = <ReporteKunaqPorImportadorRequest>{}
    this.listDetalleCliente = []
    this.ListaItemsRanking = []
    this.DateTable = []
    request.ruc = this.cliente.ruc_cliente
    request.tipo = 'GENERAL'
    this.servReportes.ReporteKunaqPorImportador(request).subscribe(response => {
      this.listDetalleCliente = response.datos.result
    })

    request.tipo = 'AGENTE'
    this.servReportes.ReporteKunaqPorImportadorTipo(request).subscribe(response => {
      response.datos.result.forEach(r => {
        this.ListaItemsRanking.push(r)
      })
      // this.filtrarDetalle()
    })

    request.tipo = 'LINEA'
    this.servReportes.ReporteKunaqPorImportadorTipo(request).subscribe(response => {
      response.datos.result.forEach(r => {
        this.ListaItemsRanking.push(r)
      })
    })

    request.tipo = 'MERCADERIA'
    this.servReportes.ReporteKunaqPorImportadorTipo(request).subscribe(response => {
      response.datos.result.forEach(r => {
        this.ListaItemsRanking.push(r)
      })
    })

    request.tipo = 'PUERTO'
    this.servReportes.ReporteKunaqPorImportadorTipo(request).subscribe(response => {
      response.datos.result.forEach(r => {
        this.ListaItemsRanking.push(r)
      })
    })


    request.tipo = 'FLETE LINEA'
    this.servReportes.ReporteFletePorLinea(request).subscribe(response => {
      console.log(response)
      this.DateTable = response.datos.result
    })



  }

  partidasArancelariasMDT: newmetadate[] = [
    { field: 'origen', title: 'ORIGEN', inputType: 'icon', function: (row: any) => this.validarFilas(row) },
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'partida', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
    { field: 'cnt20', title: 'CNT 20', inputType: 'int', rowFooter: 'sum' },
    { field: 'cnt40', title: 'CNT 40', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' },
  ]


  validarFilas(row: ItemsReporteCarteraEjecutivoPartidas): string {
    let value = ''

    if (row.origen == 'CHINA') {
      value = "../../../../../../assets/iconos/china.svg"
    } else {
      value = "../../../../assets/iconos/mundo.svg"
    }

    return value
  }

  partidasMDT: newmetadate[] = [
    { field: 'partida', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
  ]
  partidasArancelariasDT: ItemsReporteCarteraEjecutivoPartidas[] = []
  partidasDT: any[] = []
  searchSecondTab() {
    const request = <ReporteCarteraEjecutivoRequest>{}
    request.correoejecutivo = ''
    request.tipo = 'PARTIDAS'
    request.ruc_cliente = this.cliente.ruc_cliente
    this.servReportes.ReporteCarteraEjecutivoPartidas(request).subscribe(response => {
      this.partidasArancelariasDT = response.datos.result
      const data: ItemsReporteCarteraEjecutivoPartidas[] = response.datos.result

      data.forEach(r => {
        if (!this.partidasDT.some(item => item.partida === r.partida)) {
          this.partidasDT.push({ partida: r.partida });
        }
      })

      this.partidasDT = Array.from(this.partidasDT)
    })
  }

  lineaAgenteDT: ItemsReporteFletePorLineaDetalleResponse[] = []
  changeLine(event: Event, row: ItemsReporteFletePorLineaResponse) {
    // console.log(event.target as HTMLInputElement)
    // console.log(row)
    const input = event.target as HTMLInputElement
    const value = input.checked
    console.log(value)

    if (value) {
      row.detalle.forEach(e => {
        if (row.agente === e.agente && !this.lineaAgenteDT.includes(e)) {
          this.lineaAgenteDT.push(e)
        }
      })

      // Ordenar los datos por agente y TEUS de mayor a menor
      this.lineaAgenteDT.sort((a, b) => {
        // Ordenar por agente (alfabéticamente)
        if (a.agente < b.agente) {
          return -1;
        }
        if (a.agente > b.agente) {
          return 1;
        }

        // Si los agentes son iguales, ordenar por TEUS (de mayor a menor)
        return b.teus - a.teus; // Asumiendo que TEUS es un número
      });

    } else {
      this.lineaAgenteDT = this.lineaAgenteDT.filter(r => r.agente !== row.agente)
    }
    this.lineaAgenteDT = Array.from(this.lineaAgenteDT)
  }

  lineaPartidaDT: ItemsReporteCarteraEjecutivoPartidas[] = []
  changePartida(event: Event, row: any) {
    // console.log(event.target as HTMLInputElement)
    // console.log(row)
    const input = event.target as HTMLInputElement
    const value = input.checked
    console.log(value)

    if (value) {
      this.partidasArancelariasDT.forEach(e => {
        if (e.partida === row.partida && !this.lineaPartidaDT.includes(e)) {
          this.lineaPartidaDT.push(e)
        }
      })

      this.lineaPartidaDT.sort((a, b) => {
        // Ordenar por agente (alfabéticamente)
        if (a.agente < b.agente) {
          return -1;
        }
        if (a.agente > b.agente) {
          return 1;
        }

        // Si los agentes son iguales, ordenar por linea (alfabéticamente)
        if (a.linea < b.linea) {
          return -1;
        }
        if (a.linea > b.linea) {
          return 1;
        }

        // Si tanto agente como linea son iguales
        return 0;
      });

    } else {
      this.lineaPartidaDT = this.lineaPartidaDT.filter(r => r.partida !== row.partida)
    }
    this.lineaPartidaDT = Array.from(this.lineaPartidaDT)
  }

  close() {
    this.reference.close()
  }

}
