<div class="a-titulo">Reporte cartera clientes - ejecutivo</div>
<div class="a-w1-column">
  <app-datatable [filterGlobal]="true" [metadataTable]="resumenMDT" [dataTable]="resumenDT">
    <ng-template #addHeaderTop>
      <tr class="header-table">
        <th colspan="2"></th>
        <th colspan="2"></th>
        <!--style="font-size: 10px;text-align: center;font-weight: 700;" class="header align-items-center items-header" FRECUENCIA -->
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
      </tr>
    </ng-template>

  </app-datatable>
  <app-datatable [filterGlobal]="true" [metadataTable]="detalleMDT" [dataTable]="detalleDT" [customButtons]="buttonDT">
    <ng-template #addHeaderStart>
      <th></th>
    </ng-template>
    <ng-template #addBodyStart let-row="row">
      <td>
        <div style="text-align: center;">
          <p-button icon="pi pi-chart-bar" pTooltip="Editar"
            styleClass="p-button-rounded p-button-secondary p-button-text" (onClick)="viewDetail(row)"></p-button>
        </div>
      </td>
    </ng-template>

  </app-datatable>
</div>

<footer>
  <div class="contenido-pie">
    <p></p>
  </div>
</footer>