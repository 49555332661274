import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { co } from '@fullcalendar/core/internal-common';
import { Subject } from 'rxjs';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { tarifaRequest } from 'src/app/proforma/domain/request/proforma_request';
import { UtilService } from 'src/app/services/util.service';
import { style } from 'src/app/shared/components/datatable/domain/datatable-domain';


@Component({
  selector: 'app-transportistas-adicionales',
  templateUrl: './transportistas-adicionales.component.html',
  styleUrls: ['./transportistas-adicionales.component.css']
})
export class TransportistasAdicionalesComponent {

  protected _onDestroy = new Subject<void>();
  @Output() dataUpdated = new EventEmitter<any>();
  group: FormGroup;
  dataTransportistas: any
  listaPaquete: any[] = []
  cantidad: number;
  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }

  constructor(
    private readonly reference: MatDialogRef<TransportistasAdicionalesComponent>,
    private readonly proformaService: ProformaRepository, private util: UtilService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    const dataRecibida = JSON.parse(this.data);
    if (dataRecibida) {
      this.dataTransportistas = dataRecibida.dataTransportistas || [];
      this.listaPaquete = (dataRecibida.listaPaquete || []).map((item: any) => ({
        ...item,
        codigo: Number(item.codigo) || 0
      }));
    }
    // console.log("info transportistas adicionales", this.dataTransportistas)
    console.log("this.listaPaquete", this.listaPaquete)
  }

  readOnlyTransportista1txt: boolean
  readOnlyTransportista2txt: boolean
  readOnlyContTransportista1: boolean
  readOnlyContTransportista2: boolean
  readOnlyTransportista3txt: boolean
  readOnlyContTransportista3: boolean
  readOnlyContenedor1: boolean
  readOnlyContenedor2: boolean
  readOnlyContenedor3: boolean

  ngOnInit(): void {
    this.group = new FormGroup({
      selectedTransportista: new FormControl(this.dataTransportistas.opcionSeleccionada, null),
      transportistaAdicional1: new FormControl(this.dataTransportistas.transportistaMain, null),
      numeroContrato1: new FormControl(this.dataTransportistas.serviceContractMain, null),
      codContenedor1: new FormControl(parseInt(this.dataTransportistas.pacK_CodigoMain), null),
      transportistaAdicional2: new FormControl(this.dataTransportistas[0].transportista1, null),
      numeroContrato2: new FormControl(this.dataTransportistas[0].serviceContract, null),
      codContenedor2: new FormControl(this.dataTransportistas[0].pacK_Codigo1, null),
      transportistaAdicional3: new FormControl(this.dataTransportistas[0].transportista2, null),
      numeroContrato3: new FormControl(this.dataTransportistas[0].serviceContract2, null),
      codContenedor3: new FormControl(this.dataTransportistas[0].pacK_Codigo2, null),
    });

    this.readOnlyTransportista1txt = true
    this.readOnlyTransportista2txt = true
    this.readOnlyContTransportista1 = true
    this.readOnlyContTransportista2 = true
    this.readOnlyTransportista3txt = true
    this.readOnlyContTransportista3 = true
    this.readOnlyContenedor1 = true
    this.readOnlyContenedor2 = true
    this.readOnlyContenedor3 = true

    this.group.get('codContenedor1').setValue(parseInt(this.dataTransportistas.pacK_CodigoMain))
    this.group.get('codContenedor2').setValue(parseInt(this.dataTransportistas[0].pacK_Codigo1))
    this.group.get('codContenedor3').setValue(parseInt(this.dataTransportistas[0].pacK_Codigo2))
  }

  closeModal() {
    this.reference.close();
  }

  guardar() {

    const dataEnviar = {
      opcionSeleccionada: this.group.get('selectedTransportista').value,
      "1": {
        codigoTransportista: this.dataTransportistas.enT_CodTransportistaAdicMain,
        transportistaNombre: this.group.get('transportistaAdicional1').value,
        codigoContrato: this.dataTransportistas.conT_CodigoMain,
        numeroContrato: this.group.get('numeroContrato1').value,
        packCodigo: this.group.get('codContenedor1').value
      },
      "2": {
        codigoTransportista: this.dataTransportistas[0].enT_CodTransportistaAdic1,
        transportistaNombre: this.group.get('transportistaAdicional2').value,
        codigoContrato: this.dataTransportistas[0].conT_Codigo1,
        numeroContrato: this.group.get('numeroContrato2').value,
        packCodigo: this.group.get('codContenedor2').value
      },
      "3": {
        codigoTransportista: this.dataTransportistas[0].enT_CodTransportistaAdic2,
        transportistaNombre: this.group.get('transportistaAdicional3').value,
        codigoContrato: this.dataTransportistas[0].conT_Codigo2,
        numeroContrato: this.group.get('numeroContrato3').value,
        packCodigo: this.group.get('codContenedor3').value
      }
    };

    // console.log(dataEnviar);
    this.reference.close(dataEnviar);
    this.dataUpdated.emit(dataEnviar);
    this.ngOnDestroy()
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


}
