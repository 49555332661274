<!-- <form fxLayout="column" fxLayoutAlign="space" class="controlclaros">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-card-header class="cierra">
      <div class="cierra_caja1">
        <mat-card-title>CAMBIAR CONTRASEÑA</mat-card-title>
      </div>
      <div class="cierra_caja2">
        <button mat-icon-button (click)="cerrarVentana()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card>

      <mat-card-content>

        <form [formGroup]="cambiarContrasenaForm" (ngSubmit)="cambiarContrasena()">
          <mat-form-field appearance="outline">
            <mat-label>Usuario</mat-label>
            <input matInput formControlName="usuario" type="text" required>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Contraseña Antigua</mat-label>
            <input matInput formControlName="antiguaContrasena" type="password" required
              [type]="hide ? 'password':'text' ">
            <i class="material-icons" matSuffix (click)="hide =!hide">{{hide ? 'visibility':'visibility_off'}}</i>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Nueva Contraseña</mat-label>
            <input matInput formControlName="nuevaContrasena" type="password" required
              [type]="hide1 ? 'password':'text'" (input)="ingresaTexto()" [ngStyle]="borderColorV">
            <i class="material-icons" matSuffix *ngIf="muestra" [ngStyle]="color">{{valido ?
              'done':'report_problem'}}</i>
            <i class="material-icons" matSuffix (click)="hide1 =!hide1">{{hide1 ? 'visibility':'visibility_off'}}</i>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Confirmar Nueva Contraseña</mat-label>
            <input matInput formControlName="confirmarNuevaContrasena" type="password" required
              [type]="hide2 ? 'password':'text'" (input)="ingresaTexto()">
            <i class="material-icons" matSuffix *ngIf="muestra" [ngStyle]="color">{{valido ?
              'done':'report_problem'}}</i>
            <i class="material-icons" matSuffix (click)="hide2 =!hide2">{{hide2 ? 'visibility':'visibility_off'}}</i>
          </mat-form-field>


          <button type="submit" mat-raised-button color="primary">Cambiar Contraseña</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

</form> -->


<div class="a-main">
  <br>
  <div class="cierra_caja2" style="display: flex;flex-direction: row-reverse;">
    <button mat-icon-button (click)="cerrarVentana()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <br>
  <form [formGroup]="group" class="a-w1-column">
    <span class="p-float-label">
      <input pInputText id="username" formControlName="user" />
      <label htmlFor="username">Usuario</label>
    </span>
    <span class="p-float-label">
      <p-password formControlName="old" [toggleMask]="true" [feedback]="false"></p-password>
      <label htmlFor="username">Contraseña antigua</label>
    </span>
    <span class="p-float-label">
      <p-password class="a-w1" [toggleMask]="true" formControlName="new" appendTo="body" (input)="validate('new')">
        <ng-template pTemplate="header">
          <h6>Elige una contraseña</h6>
        </ng-template>
        <ng-template pTemplate="footer">
          <p-divider></p-divider>
          <p class="mt-2">Sugerencias</p>
          <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
            <li>Minimo 8 caracteres</li>
            <li>Alterne entre letras mayusculas y minusculas</li>
            <li>Ingrese al menos un caracter especial</li>
            <li>Ingrese al menos un número</li>
          </ul>
        </ng-template>
      </p-password>
      <label htmlFor="username">Contraseña nueva</label>

    </span>
    <span class="p-float-label">
      <p-password [toggleMask]="true" formControlName="confirm" [feedback]="false" (input)="validate('confirm')"
        tabindex="false"></p-password>
      <label htmlFor="username">Confirmar contraseña</label>
    </span>
    <div style="text-align: center;font-size: 10; text-transform: uppercase; font-weight: 300;">{{mensaje}}</div>
    <div class="a-centers"><p-button icon="pi pi-save" label="Cambiar Contraseña" [disabled]="!validateButton"
        (onClick)="updatePassword()"></p-button></div>

  </form>

</div>