import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataRow, readonlyMetadataTable } from './domain/readonly-data-table.domain';
import { UtilService } from 'src/app/services/util.service';
import { es } from 'date-fns/locale';
import { text } from 'body-parser';
import { ConfirmationService, MenuItem, SortEvent } from 'primeng/api';
import { style } from './domain/readonly-data-table.domain';
import { Table } from 'primeng/table';
import { ContextMenu } from 'primeng/contextmenu';
@Component({
  selector: 'app-readonly-data-table',
  templateUrl: './readonly-data-table.component.html',
  styleUrls: ['./readonly-data-table.component.css']
})
export class ReadonlyDataTableComponent {
  bResult: any;
  isHovered = false;//Es para la simulaciond de hover de un checkbox

  constructor(public readonly util: UtilService, private confirmationService: ConfirmationService,) { }

  @ViewChild('dt1') table: Table
  @ViewChild('dt2') table2: Table
  @Input() caption: boolean = false;
  @Input() caption2: boolean = false;
  @Input() nombreListaHijos: string;
  @Input() dataTable: any[];
  @Input() title: string = ''
  @Input() filterGlobal: boolean = false;
  @Input() filterGlobalHijos: boolean = false;
  @Input() metadataTable: readonlyMetadataTable[] = [];
  @Input() key: string = ''
  @Input() rows: any[] = [20, 30, 50]
  @Input() filter: boolean = true;
  data: any[] = []
  datatreeview: any[] = []
  metadatatable: readonlyMetadataTable[] = []
  paginator: boolean = true
  metadatechildren: any[] = []
  metadatepather: any[] = []
  valorResize = false
  @Input() style: style;
  // selectedDates: { [key: string]: Date } = {};

  Valor:string
  styleHeader: { [key: string]: string } = {}
  styleBody: { [key: string]: string } = {}
  filtrosGlobal: any[] = []
  filtrosGlobalHijos: any[] = []
  sortFieldPadres: string; 
  sortOrderPadres: number; 
  sortFieldHijos: string=''; 
  sortOrderHijos: number; 

  ngOnInit() {
    // this.sortFieldHijos='clientes'
    //==============================================NO BORRAR===========================================================
    // // Recuperar los datos desde sessionStorage
    // const ordenamientoColumnasPadres = sessionStorage.getItem('ordenamientoColumnasPadres');
    // const ordenamientoColumnasHijos = sessionStorage.getItem('ordenamientoColumnasHijos');

    // // Si los datos existen, parsearlos de vuelta a un objeto
    // if (ordenamientoColumnasPadres) {
    //   const sortFieldsPadres = JSON.parse(ordenamientoColumnasPadres);
    //   console.log(sortFieldsPadres);  // Aquí puedes usar los datos de ordenamiento
    //   this.sortFieldPadres=sortFieldsPadres[0].field
    //   this.sortOrderPadres=sortFieldsPadres[0].order
    // }
    
    // if (ordenamientoColumnasHijos) {
    //   const sortFieldsHijos = JSON.parse(ordenamientoColumnasHijos);
    //   console.log(sortFieldsHijos);  // Aquí puedes usar los datos de ordenamiento
    //   this.sortFieldHijos=sortFieldsHijos[0].field
    //   this.sortOrderHijos=sortFieldsHijos[0].order
    // }
    //============================================== FIN NO BORRAR===========================================================
    this.Valor="7";
    // const textarea = document.querySelector('textarea');
    // textarea.style.height = null; // Reset the height style
    //   textarea.rows = 1;
    if (this.style) {
      if (this.style.header) {
        const r = this.style.header
        this.styleHeader = {};
        if (r.align) { this.styleHeader['text-align'] = r.align }
        if (r.size) { this.styleHeader['font-size'] = r.size }
        if (r.justify) { this.styleHeader['justify-content'] = r.justify }
      }
      if (this.style.body) {
        const r = this.style.body
        this.styleBody = {};
        if (r.align) { this.styleBody['text-align'] = r.align }
        if (r.size) { this.styleBody['font-size'] = r.size }
        if (r.justify) { this.styleBody['justify-content'] = r.justify }
      }
    }
    this.caption = this.filterGlobal === true || this.title != '' ? true : false
    this.caption2 = this.filterGlobalHijos === true || this.title != '' ? true : false
  }
  prueba(evento){
    console.log(evento)
}
  ngOnChanges(changes: SimpleChanges): void {

    // this.footer = false
    this.metadatatable = this.metadataTable

    const metadatepather = this.metadatatable.filter(r => r.type != 'array')
    this.metadatechildren = this.metadatatable.filter(r => r.type == 'array')
    console.log(this.metadatechildren)
    this.data = this.dataTable
    // metadatechildren.forEach(r => {

    //   datatreeview
    // })


    if (this.data.length > 20 && this.paginator == true) { this.paginator = true }


    if (changes['metadataTable'] && this.metadataTable && this.metadataTable.length > 0) {
      this.metadataTable.forEach(r => {
        this.filtrosGlobal.push(r.field);
        // this.cols.push(r);
      });
      console.log(this.filtrosGlobal)
    }

  }

  DespliegueHijos(row) {
    console.log(row);
    // if (this.metadataTable && this.metadataTable.length > 0) {
    //   this.filtrosGlobalHijos = this.metadataTable[this.metadataTable.length - 1].metadata
    //     .filter(r => row.hasOwnProperty(r.field)) // Filtra solo los campos que existen en row
    // }

    this.metadataTable[this.metadataTable.length - 1].metadata.forEach(r => {
      this.filtrosGlobalHijos.push(r.field);
      // this.cols.push(r);
    });


    console.log(this.filtrosGlobalHijos)
  }

  // Actualizar($event){
  //   console.log($event.checked)


  //   if (this.ActualizarFecha($event.checked)){

  //   }

  // }

  // ActualizarFecha(estado:boolean){
  //   bResult: true
  //   if (estado) {
  //     //Entra al store y devuelve true
  //     console.log("Va a actualizar")
  //   } else {
  //     //Entra al store pero con valor de fecha null sea devuelve false
  //     console.log("NO Va a actualizar")

  //   }
  //   return this.bResult
  // }

  ver(dato) {
    console.log("asd", dato)
  }
  selectedDate: Date;
  handleDateSelect(event: any) {
    this.selectedDate = event;
  }
  selectDropdown:string
  handleDropdownSelect(event:any){
    this.selectDropdown=event
    console.log(event)
  }
//   handleDateSelect(event: any, key: string) {
//     this.selectedDates[key] = event;
// }
// handleDateSelect(event, rowdata) {
//   rowdata.selectedDate = event; // Enable the button only for the current row
// }
  visible: boolean;
  toggleState: boolean = true;

  // toggleState: boolean = true;

  // showDialog() {
  //   const textarea = document.querySelector('textarea');
  //   if (this.toggleState) {
  //     // Funcion 1
  //     textarea.style.height = 'auto';
  //     textarea.style.height = textarea.scrollHeight + 'px';
  //     this.valorResize = true
  //   } else {
  //     // Funcion 2
  //     textarea.style.height = null; // Reset the height style
  //     textarea.rows = 1;
  //     this.valorResize = false

  //   }
  //   this.toggleState = !this.toggleState; // toggle the state
  // }
  visibleP_Dialog_General:boolean;
  visibleDialog: boolean;
  visibleTextArea:boolean;
  visibleTextAreaEdit:boolean;
  datoTextArea:string
  tituloP1_Dialog:string

  funcionx: any;
selectedRow: any; // Variable para guardar el row seleccionado
  showDialog(row: any, title: string, field: string, modal:string, funcion: Function, dato:string) {
    this.visibleP_Dialog_General=true
    this.visibleDialog=true
    this.visibleTextAreaEdit = true;
    this.visibleTextArea=true;
    this.tituloP1_Dialog=title
    if (modal =="textArea"){
      console.log(row)
      this.visibleDialog = false
      this.visibleTextArea=true
      this.visibleTextAreaEdit=false
      this.datoTextArea=dato

      // this.datoTextArea = row.doov_mbl;
        this.funcionx = funcion; // Guardar la función
        this.selectedRow = row; // Guardar el row seleccionado
      // this.mensajeDialog = { datoTextArea: row.doov_mbl}
    }else if(modal =="textAreaEdit"){
      console.log(row)
      this.visibleDialog = false
      this.visibleTextArea=false
      this.visibleTextAreaEdit=true
      this.datoTextArea=row.nvia_usercomments

      // this.datoTextArea = row.doov_mbl;
        this.funcionx = funcion; // Guardar la función
        this.selectedRow = row; // Guardar el row seleccionado
      // this.mensajeDialog = { datoTextArea: row.doov_mbl}
    }

  }

  onConfirm() {
    if (this.funcionx && this.selectedRow) {
        this.funcionx({ event: {}, row: this.selectedRow, dato: this.datoTextArea });
    }
}

  // showDialog() {
  //   const textarea = document.querySelector('textarea');
  //     textarea.rows = 10;
  // }



  // showDialog() {
  //   // this.visible = true;
  //   const textarea = document.querySelector('textarea');
  // this.showAllRows = !this.showAllRows;
  // textarea.rows = this.showAllRows ? null : 1;
  // }

  isDisabled() {
    this.selectedDate = null;
  }
  isDisabledDropdwon(){
    this.selectDropdown=null;
  }
//   isDisabled(key: string) {
//     this.selectedDates[key] = null;
// }


  // PanelConfirmacion(funcion: (dato: any) => void) {//mensaje:string,
  //   this.confirmationService.confirm({
  //     target: event.target,
  //     message: "ABCD",
  //     icon: 'pi pi-exclamation-triangle',

  //     accept: () => {        
  //       funcion(dato);
  //       console.log("Se hace")
  //     },
  //     reject: () => {
  //     }
  //   });
  // }

  // des(): string {
  //   alert('zzz')
  //   return ''
  // }

  generateStyle(data: any): { [key: string]: string } {
    let value = {}
    if (data) {
      if (data.align) { value['text-align'] = data.align }
      if (data.size) { value['font-size'] = data.size }
      if (data.justify) { value['justify-content'] = data.justify }
    }
    return value
  }

  viewData() {
    console.log('DATATABLE', this.dataTable)
    // console.log('PRECARGA', this.precarga)
    console.log('KEY', this.key)
    // console.log('COLS', this.cols)
    // console.log('FILTRO GLOBAL', this.filtrosGlobal)
    // console.log('METADATATABLE', this.metadataTable)
    // console.log('CAPTION', this.caption)
    // console.log('TABLE PRIME', this.table)
    // if (this.group) {
    //   console.log('GROUP', this.group);
    //   console.log('GROUP VALUE', this.group.value);
    // }

  }

  isPopupVisible: boolean = false;

// Método para mostrar el popup
showPopup() {
    this.isPopupVisible = true;
}

// Método para ocultar el popup
hidePopup() {
    this.isPopupVisible = false;
}

bool(value: any): boolean {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return !!value; // Convierte a booleano para otros casos (como null, undefined, 0, etc.)
}

handleClick(){
  console.log("ohala")
}

getButtonStyle(isActive: boolean): any {
  if (isActive) {
    return {}; // Estilo por defecto cuando es true
  } else {
    return {
      'background-color': 'white',
      'border': '2px solid #ced4da',
      'transition': 'background-color .2s,color .2s,border-color .2s,box-shadow .2s'
    };
  }
}
clear(table: Table) {
  table.clear();
  this.filterTableData.emit([]);
}
clear2(table2: Table) {
  table2.clear();
}

  selectedProduct: any;
  @Input() contextMenu: MenuItem[]
  @ViewChild('expmenu') expmenu: ContextMenu
  @Output() selectedRowData = new EventEmitter<any>()
  onRowRightClick(event: MouseEvent, rowdata: any) {
    event.preventDefault();
    this.selectedRowData.emit(rowdata);
  }

  actualizarTabla(tabla){
    console.log("read", tabla._value)
  }

  
  // Usar ViewChild para obtener la referencia de la tabla
  @ViewChild('dt1') dt1: Table | undefined;

  // Método para filtrar globalmente
  onGlobalFilter(value: string): void {
    if (this.dt1) {
      // Aplica el filtro global a la tabla
      this.dt1.filterGlobal(value, 'contains');
      // let filtrado =this.dt1.filterGlobal(value, 'contains');
      // console.log("xxx#",filtrado)
      // // Esperar a que los datos se actualicen (esto es importante)
      // setTimeout(() => {
      //   // Accede a los datos filtrados
      //   const filteredData = this.dt1._value;  // Aquí accedes a los datos filtrados
      //   console.log('Datos filtrados:', filteredData);
      // }, 0);  // Usar setTimeout para asegurar que la tabla haya sido actualizada
    }
  }
  
  @Output() filterTableData = new EventEmitter<any[]>();

  filteredData: any[] = [];
  
  manualFilter(value: string): void {
    if (this.dt1 && this.dt1._value) {
      const searchValue = value.toString().toLowerCase();
      this.filteredData = this.dt1._value.filter(item => {
        return this.containsInParentProperties(item, searchValue);
      });

      console.log('Datos filtrados:', this.filteredData);

      // Emitir los datos filtrados a TS2
      this.filterTableData.emit(this.filteredData);
    }
  }

  containsInParentProperties(item: any, searchValue: string): boolean {
    for (let key in item) {
      if (item.hasOwnProperty(key)) {
        const propertyValue = item[key];
        if (propertyValue && propertyValue.toString) {
          if (propertyValue.toString().toLowerCase().includes(searchValue)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  checkList: any[] = []
  onChangeCheckList(event: Event, row: any): void {

    if (row.index === undefined || row.index === null || row.index === '') {
      console.log('La fila necesita contener un atributo index valido.')
      return
    }

    if (event['checked']) {
      this.checkList.push(row)
    } else {
      this.checkList = this.checkList.filter(item => item.index !== row.index)
    }
  }

  resetCheckList():void{
    this.checkList = []
  }
  dropdownList: any[] = []
  rowdropdown:any[]=[]
  listacombinada:any[]=[]
  onChangeDropdownList(event, row){
    console.log(event)
    console.log(row)
    event.value//valor q se eligió
    this.dropdownList.push(event.value)
    this.rowdropdown.push(row)
    console.log(this.dropdownList)
    console.log(this.rowdropdown)

    this.listacombinada = this.dropdownList.map((value, index) => ({
      dropdownValue: value,
      rowValue: this.rowdropdown[index]
  }));
  
  console.log("Lista combinada ",this.listacombinada);
  }

  onButtonClick(event: MouseEvent, item2: any): void {
    event.stopPropagation();  // Detiene la propagación del clic

    // Llama a la función de tu lógica aquí
    item2.function(this.listacombinada);
}

isSorted: boolean = null;
@ViewChild('dt2') dt2: Table;
customSort(event: SortEvent) {
 
  
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null) result = -1;
    else if (value1 != null && value2 == null) result = 1;
    else if (value1 == null && value2 == null) result = 0;
    else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
    else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

    return event.order * result;
  });

  this.sortFieldHijos=event.field
  this.sortOrderHijos=event.order


}



verDetalle(){
  console.log(this.table)
  console.log(this.table2)
  // this.table2._sortField = 'cliente'
  this.sortFieldHijos='cliente'
}


}
