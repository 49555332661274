export const environment = {
  production: true,
 //PATH_API: 'http://10.0.2.31:49160/api'
 PATH_API: 'https://apis.delfingroupco.com.pe:443/api'
 
 //PATH_API: 'https://apis.delfingroupco.com.pe:443/api'

 //PATH_API: 'http://10.0.2.5:80/api'
// PATH_API: 'http://10.0.2.26:443/api'

// PATH_API: 'http://10.0.2.26:80/api'
};
