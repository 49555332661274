import { Observable } from "rxjs";
import { ListarAccesoRequest, ListarMenuPorRolRequest, ListarMenuPrincipalRequest, ListarMenuRequest, ListarRolesRequest, ListarRolUsuarioRequest, ListarUsuariosRequest, TransaccionAccesoRequest, TransaccionMenuRequest, TransaccionRolesRequest, TransaccionUsuariosRequest } from "./request/gestion-usuario-request";
import { ListarAccesoResponse, ListarMenuPorRolResponse, ListarMenuPrincipalResponse, ListarMenuResponse, ListarRolesResponse, ListarRolUsuarioResponse, ListarUsuariosResponse, TransaccionAccesoResponse, TransaccionMenuResponse, TransaccionRolesResponse, TransaccionUsuariosResponse } from "./response/gestion-usuario-response";

export abstract class GestionUsuarioRepository {
    abstract listarUsuarios(prolrequest: ListarUsuariosRequest): Observable<ListarUsuariosResponse>
    abstract transaccionUsuarios(prolrequest: TransaccionUsuariosRequest): Observable<TransaccionUsuariosResponse>
    abstract listarRoles(prolrequest: ListarRolesRequest): Observable<ListarRolesResponse>
    abstract transaccionRoles(prolrequest: TransaccionRolesRequest): Observable<TransaccionRolesResponse>
    abstract listarMenus(prolrequest: ListarMenuRequest): Observable<ListarMenuResponse>
    abstract transaccionMenu(prolrequest: TransaccionMenuRequest): Observable<TransaccionMenuResponse>
    abstract listarAcceso(prolrequest: ListarAccesoRequest): Observable<ListarAccesoResponse>
    abstract transaccionAcceso(prolrequest: TransaccionAccesoRequest): Observable<TransaccionAccesoResponse>
    abstract listarRolUsuario (prolrequest: ListarRolUsuarioRequest): Observable<ListarRolUsuarioResponse>
    abstract listarMenuPorRol (prolrequest: ListarMenuPorRolRequest): Observable<ListarMenuPorRolResponse>
    abstract listarMenuPrincipal (prolrequest: ListarMenuPrincipalRequest): Observable<ListarMenuPrincipalResponse>
}